import { createSelector } from 'reselect'
import { selectZoomDetails } from '../view/viewSelectors'
import { ZoomLevel, PinType, PinTypeName } from '../../configuration/constants'

const getFiltersState = (state) => state.filters

export const selectDepartureMonth = createSelector(
  getFiltersState,
  (state) => state.selectedDepartureMonth
)

export const selectAllDeploymentsFilters = createSelector(
  getFiltersState,
  (state) => state.allDeployments
)

export const selectValidPins = createSelector(
  getFiltersState,
  selectZoomDetails,
  (state, zoomDetails) => {
    switch (zoomDetails.zoomLevel) {
      case ZoomLevel.WORLD:
        return PinType.filter((pin) =>
          [PinTypeName.REGION, PinTypeName.HOME_PORT].includes(pin.type)
        )
      case ZoomLevel.REGION:
        return PinType.filter((pin) =>
          [PinTypeName.HOME_PORT, PinTypeName.PORT_OF_CALL].includes(pin.type)
        )
      case ZoomLevel.PORT:
        return PinType.filter((pin) =>
          [
            PinTypeName.BRAND_CELEBRITY,
            PinTypeName.BRAND_ROYAL_CARRIBEAN,
          ].includes(pin.type)
        )
      default:
        return null
    }
  }
)

export const selectPinFilters = createSelector(
  getFiltersState,
  selectZoomDetails,
  (state, zoomDetails) => {
    return state.pins[zoomDetails.zoomLevel]
  }
)

export const selectShipDeploymentsFilter = createSelector(
  getFiltersState,
  (state) => state.shipDeployments
)

export const selectRegionDeploymentsFilter = createSelector(
  getFiltersState,
  (state) => state.regionDeployments
)

export const selectPortDeploymentsFilter = createSelector(
  getFiltersState,
  (state) => state.portDeployments
)
