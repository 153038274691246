import { actionTypes } from './filtersActions'
import { PinTypeName, ZoomLevel } from '../../configuration/constants'

const thisYear = new Date().getFullYear().toString()

const initialState = {
  selectedDepartureMonth: '',
  allDeployments: {
    brand: '',
    shipClass: '',
    ship: '',
    region: '',
    port: '',
    year: thisYear,
  },
  pins: (() => {
    const pins = {}
    pins[ZoomLevel.WORLD] = [PinTypeName.REGION]
    pins[ZoomLevel.REGION] = [PinTypeName.HOME_PORT]
    pins[ZoomLevel.PORT] = [
      PinTypeName.BRAND_CELEBRITY,
      PinTypeName.BRAND_ROYAL_CARRIBEAN,
    ]
    return pins
  })(),
  shipDeployments: {
    year: thisYear,
    month: [],
    region: [],
    portOfCall: [],
    departurePort: '',
  },
  portDeployments: {
    year: thisYear,
    ship: [],
    month: [],
    portOfCall: [],
  },
  regionDeployments: {
    year: thisYear,
    ship: [],
    month: [],
    portOfCall: [],
  },
}

function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_DEPARTURE_MONTH:
      return { ...state, selectedDepartureMonth: action.payload }
    case actionTypes.SET_DEPLOYMENT_FILTER:
      return {
        ...state,
        allDeployments: {
          ...state.allDeployments,
          ...action.payload, // override with new filter value
        },
      }
    case actionTypes.SET_RESOURCES_FILTER:
      return {
        ...state,
        resources: {
          ...state.resources,
          ...action.payload, // override with resources filter
        },
      }
    case actionTypes.TOGGLE_PIN_FILTER:
      if (action.payload.zoomLevel === 'WORLD' && action.payload.pinTypeName) {
        return {
          ...state,
          pins: {
            ...state.pins,
            WORLD: [action.payload.pinTypeName],
          },
        }
      }

      if (action.payload.zoomLevel === 'REGION' && action.payload.pinTypeName) {
        return {
          ...state,
          pins: {
            ...state.pins,
            REGION: [action.payload.pinTypeName],
          },
        }
      }

      if (
        state.pins[action.payload.zoomLevel].includes(
          action.payload.pinTypeName
        )
      ) {
        const pins = { ...state.pins }
        pins[action.payload.zoomLevel] = pins[action.payload.zoomLevel].filter(
          (pinType) => pinType !== action.payload.pinTypeName
        )
        return {
          ...state,
          pins,
        }
      } else {
        const pins = { ...state.pins }
        pins[action.payload.zoomLevel] = [
          ...pins[action.payload.zoomLevel],
          action.payload.pinTypeName,
        ]
        return {
          ...state,
          pins,
        }
      }
    case actionTypes.SET_SHIP_DEPLOYMENTS_FILTER:
      return {
        ...state,
        shipDeployments: {
          ...state.shipDeployments,
          ...action.payload,
        },
      }
    case actionTypes.SET_REGION_DEPLOYMENTS_FILTER:
      return {
        ...state,
        regionDeployments: {
          ...state.shipDeployments,
          ...action.payload,
        },
      }
    case actionTypes.SET_PORT_DEPLOYMENTS_FILTER:
      return {
        ...state,
        portDeployments: {
          ...state.portDeployments,
          ...action.payload,
        },
      }
    default:
      return state
  }
}

export default filtersReducer
