import { createSelector } from 'reselect'
import { selectAllDeploymentsFilters } from '../filters/filtersSelectors'
import { selectShips } from '../ships/shipsSelectors'
import { selectRegionsIndexed } from '../regions/regionsSelectors'
import { selectSailings, selectPackages } from '../sailings/sailingsSelectors'

// Output: array of { ship, shortCode, sailsTo }
//  - with matching brand
//  - with matching shipClass
//  - with sailings that have matching region
//  - with sailings that have departure port
//  - with sailings in year

const selectAllDeploymentsViewContent = createSelector(
  selectAllDeploymentsFilters,
  selectShips,
  selectSailings,
  selectPackages,
  selectRegionsIndexed,
  (filters, ships, sailings, packages, regions) => {
    const compareBrand = (x) => x.brand_code === filters.brand
    const compareClass = (x) => x.class === filters.shipClass
    const compareYearMonth = (x) => x.startsWith(filters.year.toString())
    const compareShip = (x) => x.ship_code === filters.ship

    //
    // Brand / shipClass filters limit ships immediately
    //
    let filteredShips = filters.brand ? ships.filter(compareBrand) : ships
    filteredShips = filters.shipClass
      ? ships.filter(compareClass)
      : filteredShips
    filteredShips = filters.ship ? ships.filter(compareShip) : filteredShips

    //
    // Port / Region filters limit which packages are valid.
    //
    const validPackages = {}
    Object.keys(packages).forEach((packageId) => {
      const packageInfo = packages[packageId]
      const regionOkay =
        !filters.region || packageInfo.destinationCode === filters.region
      const portOkay = !filters.port || packageInfo.ports.includes(filters.port) // use packages visit this port

      if (regionOkay && portOkay) {
        validPackages[packageId] = packageInfo
      }
    })

    //
    // Year filtering limits sailing date keys
    //
    const validSailingMonths = Object.keys(sailings).filter(compareYearMonth)
    const unverifiedShipCodes = filteredShips.reduce((acc, cur) => {
      acc[cur.ship_code] = true
      return acc
    }, {})

    validSailingMonths.forEach((yearMonth) => {
      const monthSailings = sailings[yearMonth]
      Object.keys(unverifiedShipCodes).forEach((shipCode) => {
        const monthSailingsForShip = monthSailings[shipCode] || []
        if (monthSailingsForShip.find((x) => !!validPackages[x.packageId])) {
          delete unverifiedShipCodes[shipCode]
        }
      })
    })

    //
    // Get region strings applicable for filter
    //
    const regionsByShip = {}
    Object.values(validPackages).forEach((packageInfo) => {
      const { shipCode, destinationCode } = packageInfo
      const { name: regionName } = regions[destinationCode]

      if (!regionsByShip[shipCode]) {
        regionsByShip[shipCode] = new Set()
      }
      regionsByShip[shipCode].add(regionName)
    })

    //
    // Finally we have only the valid ships, let's build the output..
    //
    return filteredShips
      .filter((x) => !unverifiedShipCodes[x.ship_code])
      .map((ship) => {
        const shipImage = ship.image[0] ? ship.image[0].file : null
        return {
          name: ship.name,
          sailsTo: [...(regionsByShip[ship.ship_code] || [])],
          shortCode: ship.ship_code,
          brandCode: ship.brand_code,
          image: shipImage,
        }
      })
  }
)

export default selectAllDeploymentsViewContent
