import { createSelector } from 'reselect'
import { selectZoomDetails } from '../view/viewSelectors'
import { selectRegionsIndexed } from '../regions/regionsSelectors'
import { selectPortsIndexed } from '../ports/portsSelectors'
import { ZoomLevel } from '../../configuration/constants'

const selectZoomControlDetails = createSelector(
  selectZoomDetails,
  selectRegionsIndexed,
  selectPortsIndexed,
  (zoomDetails, regions, ports) => {
    const { zoomLevel, zoomResource, upperZoomLevel, upperZoomResource } =
      zoomDetails
    let locationLabel = ''
    const backToLabel = `BACK TO ${upperZoomLevel}`

    switch (zoomLevel) {
      case ZoomLevel.PORT:
        locationLabel = ports[zoomResource].name
        break
      case ZoomLevel.REGION:
        locationLabel = regions[zoomResource].name
        break
      default:
        break
    }

    return {
      zoomLevel,
      locationLabel,
      upperZoomLevel,
      upperZoomResource,
      backToLabel,
    }
  }
)

export default selectZoomControlDetails
