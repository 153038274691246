import queryString from 'query-string'
import { configuration } from '../../api/api'

// CONSTANTS
export const FETCH_CONFIG_BEGIN = 'config/FETCH_START'
export const FETCH_CONFIG_ERROR = 'config/FETCH_ERROR'
export const FETCH_CONFIG_SUCCESS = 'config/FETCH_SUCCESS'

// ACTIONS
export const fetchConfiguration = () => async (dispatch) => {
  dispatch({ type: FETCH_CONFIG_BEGIN })
  const fetchQuery = new URLSearchParams()
  const queryParams = queryString.parse(window.location.search)

  if (
    process.env.REACT_APP_FLOW_CLIENT_ID &&
    process.env.REACT_APP_FLOW_CLIENT_ID !== 'null'
  ) {
    fetchQuery.append('clientId', process.env.REACT_APP_FLOW_CLIENT_ID)
  }

  if (queryParams.primaryLangId) {
    fetchQuery.append('primaryLangId', String(queryParams.primaryLangId))
  } else {
    fetchQuery.append('primaryLangId', 'en')
  }

  return configuration(fetchQuery.toString())
    .then((res) => {
      if (res.data && res.data['hydra:member']) {
        const payload = res.data['hydra:member'].map((config) => {
          const { id, options, labels } = config
          return {
            id,
            options,
            labels,
          }
        })

        dispatch({
          type: FETCH_CONFIG_SUCCESS,
          payload,
        })
      } else {
        dispatch({
          type: FETCH_CONFIG_ERROR,
          payload:
            'There was an error retrieving configuration - please wait a moment, then try again.',
        })
      }
    })
    .catch((err) => {
      dispatch({ type: FETCH_CONFIG_ERROR, payload: err.message })
    })
}

// REDUCER
const initialState = {
  loading: false,
  error: null,
  data: null,
}

export default function configurationReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CONFIG_BEGIN:
      return { ...state, loading: true }
    case FETCH_CONFIG_SUCCESS:
      return { ...state, loading: false, data: action.payload }
    case FETCH_CONFIG_ERROR:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

// SELECTORS
export const selectConfigurationData = (state) => state.configurationData
