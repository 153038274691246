import renderHTML from 'react-render-html'

export default function parseValidHtmlString(string) {
  const parser = new DOMParser()
  const content = parser.parseFromString(string, 'text/html')

  // check string for valid markup and render elements OR
  // strip tags and render string.
  if (
    content &&
    !/<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE).*?>|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|div|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\/\2>/i.test(
      content
    )
  ) {
    return string.replace(/<[^>]*>?/gm, '')
  }
  return renderHTML(content)
}

export function unescapeEncodedString(string) {
  const txt = document.createElement('textarea')
  txt.innerHTML = string
  return txt.value
}
