import { getAgent } from '../../api/api'

// CONSTANTS
export const FETCH_SSO_SUCCESS = 'sso/FETCH_SUCCESS'

export const fetchSso = (sso) => (dispatch) => {
  return getAgent(sso)
    .then((res) => {
      const data = res?.data?.data

      if (data) {
        return dispatch({
          type: FETCH_SSO_SUCCESS,
          payload: {
            ...data,
            sso,
          },
        })
      }
      throw new Error('not found')
    })
    .catch(() => {
      window.location.href = process.env.REACT_APP_CRUISING_POWER_LOGIN
    })
}

export default function authenticationReducer(state = null, action) {
  if (action.type === FETCH_SSO_SUCCESS) {
    return action.payload
  }

  return state
}

export const selectAgentSso = (state) => state?.authentication?.sso
