import { createSelector } from 'reselect'
import { ALLOWED_BRANDS } from '../../configuration/constants'

const getBrandState = (state) => state.brands

export const selectBrands = createSelector(getBrandState, (state) =>
  (state.data || []).filter((x) => ALLOWED_BRANDS.includes(x.brand_code))
)

export const selectBrandsIndexed = createSelector(selectBrands, (brands) => {
  return brands.reduce((acc, cur) => {
    acc[cur.brand_code] = cur
    return acc
  }, {})
})

export const selectBrandsFilterOptions = createSelector(
  selectBrands,
  (brands) => {
    const result = {}
    brands.sort((a, b) => a.name.localeCompare(b.name))
    brands.forEach((brand) => {
      result[brand.brand_code] = brand.name
    })
    return result
  }
)
