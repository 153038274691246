import { createSelector } from 'reselect'
import { MONTH_FILTER_OPTIONS } from '../../configuration/constants'
import { selectSidebarViewResource } from '../view/viewSelectors'
import { selectPackages } from '../sailings/sailingsSelectors'
import { selectRegionsIndexed } from '../regions/regionsSelectors'
import { selectPortsIndexed } from '../ports/portsSelectors'
import { selectShipsIndexed } from '../ships/shipsSelectors'

const selectRegionViewDeploymentsFilterOptions = createSelector(
  selectSidebarViewResource,
  selectPackages,
  selectRegionsIndexed,
  selectPortsIndexed,
  selectShipsIndexed,
  (regionCode, packages, regions, ports, ships) => {
    // Determine ships & ports
    const shipCodes = new Set()
    const portOfCallCodes = new Set()
    Object.values(packages).forEach((packageInfo) => {
      if (packageInfo.destinationCode === regionCode) {
        shipCodes.add(packageInfo.shipCode)
        packageInfo.ports.forEach((port) => portOfCallCodes.add(port))
      }
    })

    const compareLabels = (a, b) => (a.label < b.label ? -1 : 1)
    const shipOptions = [...shipCodes]
      .map((shipCode) => {
        return {
          value: shipCode,
          label: ships[shipCode].name,
        }
      })
      .sort(compareLabels)

    const portOfCallOptions = [...portOfCallCodes]
      .map((portCode) => {
        if (ports[portCode]) {
          return {
            value: portCode,
            label: ports[portCode].name,
          }
        }
        return null
      })
      .filter((x) => !!x)
      .sort(compareLabels)

    return [
      {
        title: 'Sailing Dates',
        options: MONTH_FILTER_OPTIONS,
        key: 'month',
        type: 'date',
      },
      {
        title: 'Ships',
        options: shipOptions,
        key: 'ship',
        type: 'destination',
      },
      {
        title: 'Port of Call',
        options: portOfCallOptions,
        key: 'portOfCall',
        type: 'port',
      },
    ]
  }
)

export default selectRegionViewDeploymentsFilterOptions
