import { createSelector } from 'reselect'

import { selectBrandsFilterOptions } from '../brands/brandsSelectors'
import { selectRegionFilterOptions } from '../regions/regionsSelectors'
import {
  selectFilterableYears,
  selectPackages,
} from '../sailings/sailingsSelectors'
import { selectShips, selectShipsFilterOptions } from '../ships/shipsSelectors'
import {
  selectPortsIndexed,
  selectPortFilterOptions,
} from '../ports/portsSelectors'
import { selectAllDeploymentsFilters } from '../filters/filtersSelectors'

export const selectShipClassOptionsByBrand = createSelector(
  selectShips,
  selectAllDeploymentsFilters,
  (allShips, { brand }) => {
    // 1. filter ships by brand
    const shipsOfBrand = allShips.filter((ship) =>
      brand ? ship.brand_code === brand : true
    )

    // 2. make those class name unique
    const optionSet = new Set()
    shipsOfBrand.forEach((ship) => {
      if (typeof ship.class === 'string') {
        optionSet.add(ship.class)
      }
    })

    // 3. merge ships class to object as options result
    return [...optionSet]
      .sort((a, b) => a.localeCompare(b))
      .reduce((acc, cur) => {
        if (cur === '0') return acc // why exist 0?
        acc[cur] = cur
        return acc
      }, {})
  }
)

export const selectRegionOptionsByShipClass = createSelector(
  selectShips,
  selectAllDeploymentsFilters,
  selectPackages,
  selectRegionFilterOptions,
  (allShips, { shipClass }, allPackages, allRegionOptions) => {
    // 1. get ships of one class
    const filteredShips = allShips.filter((ship) =>
      shipClass ? ship.class === shipClass : true
    )
    // 2. get packages for those ships
    const filteredPackages = []
    Object.values(allPackages).forEach((pkg) => {
      filteredShips.forEach((ship) => {
        if (pkg.shipCode === ship.ship_code) {
          filteredPackages.push(pkg)
        }
      })
    })

    // 3. get destinations from packages, remove duplicates
    const destinations = {}
    filteredPackages.forEach(({ destinationCode }) => {
      destinations[destinationCode] = destinationCode
    })

    // 4. get region options by destinations
    const options = {}
    Object.entries(allRegionOptions || {}).forEach(([code, name]) => {
      if (destinations[code]) {
        options[code] = name
      }
    })
    return options
  }
)

export const selectPortOptionsByRegion = createSelector(
  selectPackages,
  selectAllDeploymentsFilters,
  selectPortsIndexed,
  selectPortFilterOptions,
  (allPackages, { region }, portsIndexed, defaultPorts) => {
    // 1. default display all ports
    if (!region) return defaultPorts

    // 2. get ports which destinations is the region,
    // and all the CALL OF PORTS in this region.
    const portsForRegion = {}
    Object.values(allPackages).forEach(({ destinationCode, ports }) => {
      if (destinationCode === region) {
        ports.forEach((callOfPort) => {
          if (!portsIndexed[callOfPort]) return // not found in all port
          portsForRegion[callOfPort] = portsIndexed[callOfPort].name
        })
      }
    })

    // 3. reorder the options
    const options = {}
    Object.keys(portsForRegion)
      .sort()
      .reduce((acc, portCode) => {
        acc[portCode] = portsForRegion[portCode]
        return acc // must return acc in reducer
      }, options)

    return options
  }
)

/**
 * all options by filter type
 *
 * @returns options map
 */
const selectAllDeploymentsFilterOptions = createSelector(
  selectBrandsFilterOptions,
  selectShipClassOptionsByBrand,
  selectShipsFilterOptions,
  selectRegionOptionsByShipClass,
  selectPortOptionsByRegion,
  selectFilterableYears,
  (
    brandOptions,
    shipClassOptions,
    shipOptions,
    regionOptions,
    portOptions,
    yearOptions
  ) => {
    return {
      brand: brandOptions,
      shipClass: shipClassOptions,
      ship: shipOptions,
      region: regionOptions,
      port: portOptions,
      year: yearOptions,
    }
  }
)

export default selectAllDeploymentsFilterOptions
