import { getPorts } from '../../api/api'

export const actionTypes = {
  FETCH_START: 'ports/FETCH_START',
  FETCH_SUCCESS: 'ports/FETCH_SUCCESS',
  FETCH_ERROR: 'ports/FETCH_ERROR',
}

export const fetchPorts = () => async (dispatch) => {
  dispatch({ type: actionTypes.FETCH_START })

  try {
    const { data } = await getPorts()
    return dispatch({ type: actionTypes.FETCH_SUCCESS, payload: data })
  } catch (error) {
    return dispatch({ type: actionTypes.FETCH_ERROR })
  }
}
