import { createSelector } from 'reselect'

import { selectRegions } from '../regions/regionsSelectors'
import selectRegionPinData from './selectRegionPinData'

const selectRegionListData = createSelector(
  selectRegions,
  selectRegionPinData,
  (regions, pinData) => {
    // creating a lookup map for fast access when we do the pin loop
    // this way, we only have to loop through each set once and can just access by indexes
    const lookupMap = {}

    const results = regions
      .sort((a, b) => (a.name < b.name ? -1 : 1))
      .map((region, index) => {
        lookupMap[region.destination_code] = index
        return {
          name: region.name,
          shortCode: region.destination_code,
          enableViewOnMap: false,
        }
      })

    pinData.forEach((pin) => {
      const regionIndex = lookupMap[pin.shortCode]
      if (regionIndex !== null && results[regionIndex]) {
        results[regionIndex].enableViewOnMap = true
      }
    })

    return results
  }
)

export default selectRegionListData
