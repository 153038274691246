import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import './DeploymentChangesTable.scss'

const DeploymentChangesTable = ({ deploymentChanges }) => {
  return (
    <div className="component-deployment-changes-table">
      <div className="deployments">
        <table className="desktop">
          <thead>
            <tr>
              <th>Original Ship</th>
              <th>New Ship</th>
              <th>Itinerary</th>
              <th>Embarkation Port</th>
              <th>Itinerary Status</th>
              <th>Timing</th>
            </tr>
          </thead>
          <tbody>
            {deploymentChanges.map((row, index) => {
              // TODO: Unique identifier
              return (
                <tr key={`r_${index}`}>
                  <td>{row.originalShip}</td>
                  <td>{row.newShip}</td>
                  <td>{row.itinerary}</td>
                  <td>{row.embarkationPort}</td>
                  <td>{row.itineraryStatus}</td>
                  <td>{row.timing}</td>
                </tr>
              )
            })}
          </tbody>
        </table>

        <div className="mobile">
          {deploymentChanges.map((row, index) => {
            // TODO: Unique identifier
            return (
              <div
                className="mobile-deployment-grid-wrapper"
                key={`r_${index}`}
              >
                <div className="mobile-deployment-grid">
                  <div className="title">Original Ship</div>
                  <div>{row.originalShip}</div>
                  <div className="title">New Ship</div>
                  <div>{row.newShip}</div>
                  <div className="title">Itinerary</div>
                  <div>{row.itinerary}</div>
                  <div className="title">Embarkation Port</div>
                  <div>{row.embarkationPort}</div>
                  <div className="title">Itinerary Status</div>
                  <div>{row.itineraryStatus}</div>
                  <div className="title">Timing</div>
                  <div>{row.timing}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

DeploymentChangesTable.propTypes = {
  deploymentChanges: arrayOf(
    shape({
      originalShip: string.isRequired,
      newShip: string.isRequired,
      itinerary: string.isRequired,
      embarkationPort: string.isRequired,
      itineraryStatus: string.isRequired,
      timing: string.isRequired,
    })
  ).isRequired,
}

export default DeploymentChangesTable
