import React from 'react'
import './DeploymentFilters.scss'
import { string, arrayOf, objectOf, shape, func } from 'prop-types'
import SelectWithLabel from '../SelectWithLabel/SelectWithLabel'

const DeploymentFilters = ({ filters, filterOptions, onUpdatedFilter }) => {
  return (
    <div className="deployments-filters">
      <div className="group-wrapper">
        <div className="group">
          <div className="sub-group">
            <SelectWithLabel
              label="Brand"
              selectedValue={filters.brand}
              values={filterOptions.brand}
              placeholder="All Brands"
              onChange={onUpdatedFilter('brand')}
            />

            <SelectWithLabel
              label="Ship Class"
              selectedValue={filters.shipClass}
              values={filterOptions.shipClass}
              placeholder="All Classes"
              onChange={onUpdatedFilter('shipClass')}
            />

            <SelectWithLabel
              label="Ships"
              selectedValue={filters.ship}
              values={filterOptions.ship}
              placeholder="All Ships"
              onChange={onUpdatedFilter('ship')}
            />
          </div>
        </div>

        <div className="group group-2">
          <div className="sub-group">
            <SelectWithLabel
              label="Regions"
              selectedValue={filters.region}
              values={filterOptions.region}
              placeholder="All Regions"
              onChange={onUpdatedFilter('region')}
            />

            <SelectWithLabel
              label="Port"
              selectedValue={filters.port}
              values={filterOptions.port}
              placeholder="Select a Region"
              onChange={onUpdatedFilter('port')}
            />
          </div>
        </div>

        <div className="group year-group">
          {filterOptions.year.map((year) => (
            <button
              type="button"
              key={year}
              className={`btn year-range-btn ${
                filters.year === year ? 'selected' : ''
              }`}
              onClick={() => onUpdatedFilter('year')(year)}
            >
              {year}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

DeploymentFilters.propTypes = {
  filters: shape({
    brand: string.isRequired,
    port: string.isRequired,
    region: string.isRequired,
    shipClass: string.isRequired,
    year: string.isRequired,
  }).isRequired,
  filterOptions: shape({
    brand: objectOf(string).isRequired,
    port: objectOf(string).isRequired,
    region: objectOf(string).isRequired,
    shipClass: objectOf(string).isRequired,
    year: arrayOf(string).isRequired,
  }).isRequired,
  onUpdatedFilter: func.isRequired,
}

export default DeploymentFilters
