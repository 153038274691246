import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { func } from 'prop-types'

import BrandAndShipClassFilter from '../../components/BrandAndShipClassFilter/BrandAndShipClassFilter'
import MapAndScheduleMenuItem from '../../components/MapAndScheduleMenuItem/MapAndScheduleMenuItem'
import shipsIcon from '../../assets/icons/menu-ships-icon.svg'

import { selectBrandsFilterOptions } from '../../redux/brands/brandsSelectors'
import {
  selectShipsListData,
  selectShipsBrandFilter,
  selectShipsClassFilter,
  selectShipClassFilterOptions,
} from '../../redux/ships/shipsSelectors'
import {
  setShipsBrandFilter,
  setShipsClassFilter,
} from '../../redux/ships/shipsActions'

import './ShipsContainer.scss'
import { setViewOrigin } from '../../redux/view/viewActions'

const ShipsContainer = ({ swapView }) => {
  const dispatch = useDispatch()

  const mockHandleClickViewMap = () => {}

  const brands = useSelector(selectBrandsFilterOptions)
  const shipClasses = useSelector(selectShipClassFilterOptions)
  const ships = useSelector(selectShipsListData)
  const brandFilter = useSelector(selectShipsBrandFilter)
  const classFilter = useSelector(selectShipsClassFilter)

  const hideViewOnMap = true

  const handleScheduleClick = (ship) => {
    dispatch(setViewOrigin('Ships'))
    swapView('ship', ship.shortCode)
  }
  return (
    <div className="ships-container">
      <header>
        <div className="title-row">
          <img src={shipsIcon} alt="" />
          <div className="title">Ships</div>
        </div>

        <BrandAndShipClassFilter
          brands={brands}
          shipClasses={shipClasses}
          selectedBrand={brandFilter}
          selectedShipClass={classFilter}
          onNewBrand={(brand) => dispatch(setShipsBrandFilter(brand))}
          onNewShipClass={(shipClass) =>
            dispatch(setShipsClassFilter(shipClass))
          }
        />
      </header>

      <main>
        {ships.map((ship) => {
          return (
            <MapAndScheduleMenuItem
              key={ship.shortCode}
              name={ship.name}
              onClickSchedule={() => handleScheduleClick(ship)}
              onClickViewMap={mockHandleClickViewMap}
              hideViewOnMap={hideViewOnMap}
            />
          )
        })}
      </main>
    </div>
  )
}

ShipsContainer.propTypes = {
  swapView: func.isRequired,
}

export default ShipsContainer
