import { createSelector } from 'reselect'
import { uniq, uniqBy } from 'lodash'
import { selectDepartureMonth } from '../filters/filtersSelectors'
import { selectSailings, selectPackages } from '../sailings/sailingsSelectors'
import { selectRegionsIndexed } from '../regions/regionsSelectors'
import { selectShips } from '../ships/shipsSelectors'

const selectRegionPinData = createSelector(
  selectRegionsIndexed,
  selectSailings,
  selectPackages,
  selectDepartureMonth,
  selectShips,
  (regions, sailings, packages, departureMonth, ships) => {
    // Get set of regions from (sailing)->(package)->(destination)

    const validRegions = new Set()
    const regionData = {}
    Object.entries(sailings || {}).forEach(([monthYear, monthShips]) => {
      if (departureMonth && departureMonth !== monthYear) {
        return
      }

      Object.values(monthShips).forEach((shipMonthSailings) => {
        return shipMonthSailings.forEach((sailingData) => {
          const { packageId, sailDate } = sailingData

          const { destinationCode } = packages[packageId]
          validRegions.add(destinationCode)

          const currentDates = regionData?.[destinationCode]?.dates || []
          const currentBrands = regionData?.[destinationCode]?.brands || []
          const currentShips = regionData?.[destinationCode]?.ships || []

          currentDates.push(sailDate)
          currentBrands.push(packages[packageId]?.brand)

          if (packages[packageId]?.shipCode) {
            const matchingShip = ships?.find(
              (ship) => ship.ship_code === packages[packageId]?.shipCode
            )
            if (matchingShip) {
              currentShips.push({
                name: matchingShip.name,
                brandCode: matchingShip.brand_code,
                shipCode: matchingShip.ship_code,
              })
            }
          }

          regionData[destinationCode] = {
            destinationCode,
            dates: uniq(currentDates),
            brands: uniq(currentBrands),
            ships: uniqBy(currentShips, 'shipCode'),
          }
        })
      })
    })

    // Now output correct pin data
    const result = []
    validRegions.forEach((regionCode) => {
      const region = regions[regionCode]
      if (!region?.deployment_map_location) {
        return
      }
      result.push({
        type: 'region',
        label: region.name,
        shortCode: regionCode,
        lat: region.deployment_map_location.lat,
        lng: region.deployment_map_location.lng,
        brands: regionData?.[regionCode]?.brands || [],
        ships: regionData?.[regionCode]?.ships || [],
        dates: regionData?.[regionCode]?.dates || [],
      })
    })
    return result
  }
)

export default selectRegionPinData
