import regionPin from '../assets/icons/regions-pin.png'
import portPin from '../assets/icons/ports-pin.png'
import portOfCallPin from '../assets/icons/ports-of-call-pin.png'
import celebrityBrandPin from '../assets/icons/celebrity-pin.png'
import royalCarribeanBrandPin from '../assets/icons/royal-carribean-pin.png'

export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_API_KEY

export const DATE_FORMAT = 'MMM D, YYYY' // TODO(ebarrett): Should be MMMM, fix deployment table styling
export const ALLOWED_BRANDS = process.env.REACT_APP_ALLOWED_BRANDS.split(',')
export const ENABLE_BOOKING = process.env.REACT_APP_ENABLE_BOOKING !== 'false'
export const EQUOTE_URL = process.env.REACT_APP_EQUOTE_URL

export const DeviceType = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
}

export const ZoomLevel = {
  WORLD: 'WORLD',
  REGION: 'REGION',
  PORT: 'PORT',
}

export const BrandCode = {
  ROYAL_CARRIBBEAN: 'R',
  CELEBRITY: 'C',
}

export const PinTypeName = {
  REGION: 'region',
  HOME_PORT: 'homeport',
  PORT_OF_CALL: 'portofcall',
  BRAND_CELEBRITY: 'celebrity',
  BRAND_ROYAL_CARRIBEAN: 'royal-caribbean',
}

export const PinType = [
  {
    type: PinTypeName.REGION,
    label: 'Regions',
    legendLabel: 'Country / \nRegion',
    icon: regionPin,
  },
  {
    type: PinTypeName.HOME_PORT,
    label: 'Home Ports',
    legendLabel: 'Home \nPort',
    icon: portPin,
  },
  {
    type: PinTypeName.PORT_OF_CALL,
    label: 'Ports of Call',
    legendLabel: 'Port of \nCall',
    icon: portOfCallPin,
  },
  {
    type: PinTypeName.BRAND_CELEBRITY,
    label: 'Celebrity Ships',
    legendLabel: 'Celebrity \nShips',
    icon: celebrityBrandPin,
  },
  {
    type: PinTypeName.BRAND_ROYAL_CARRIBEAN,
    label: 'Royal Carribean Ships',
    legendLabel: 'Royal Carribean \nShips',
    icon: royalCarribeanBrandPin,
  },
]

export const MONTH_FILTER_OPTIONS = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
]

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const REGION_ZOOM_PORT_CODES = {
  AFRCA: ['*'], // Africa,
  ALCAN: ['SWD', 'YVR', 'SEA'], // This is Alaska: Seward, Vancouver, Seattle
  ATLCO: ['BYE', 'BWI', 'YHZ'], // Canada & New England,
  AUSTL: ['AKL', 'BNE', 'SYD', 'APW', 'DKY'], // Australia & New Zealand,
  BAHAM: ['BYE', 'BWI', 'MSY', 'TPA', 'MIA', 'FLL', 'CMM'], // Bahamas,
  BERMU: ['BYE', 'BWI', 'NAS'], // Bermuda,
  CARIB: ['BYE', 'BWI', 'MSY', 'GAL', 'TPA', 'MIA', 'FLL', 'SJU', 'ONX'], // Caribbean,
  DUBAI: ['*'], // Dubai/Emirates,
  DUIND: ['*'], // Dubai & India,
  EUROP: ['AMS', 'STH', 'VCE', 'ROM', 'LIS', 'BCN', 'REK', 'ACE'], // Europe,
  'FAR.E': ['YOK', 'HKG', 'SIN', 'HKD'], // Asia,
  GALAP: ['BAL'], // Galapagos,
  HAWAI: ['*'], // Hawaii,
  ISLAN: ['*'], // Repositioning,
  MEXCO: ['LAX', 'PVR'], // Mexico,
  PACIF: ['YVR', 'SAN'], // Pacific Coastal,
  SAMER: ['GIG', 'SAI', 'VAP', 'EZE', 'PDB'], // South America,
  SOPAC: ['AKL', 'BNE', 'SYD', 'APW', 'DKY'], // South Pacific,
  TPACI: ['YVR', 'HNL', 'BNE', 'SYD', 'YOK'], // Transpacific,
  'T.ATL': ['TPA', 'FLL', 'GIG', 'ROM', 'LIS'], // Transatlantic,
  'T.PAN': ['LAX', 'FLL', 'ONX'], // Panama Canal,
}
