import React, { useState, Fragment } from 'react'
import { arrayOf, node, number, string } from 'prop-types'
import './ExpandableCardLayout.scss'
import ShowMoreToggle from '../ShowMoreToggle/ShowMoreToggle'

/*
  Shows children cards in a standard layout with an optional "Show More" toggle button.
  showRows: initially shows only N rows of items regardless of the length of the children array
  showMoreRows: shows N more rows if the "Show More" toggle is clicked. Note that Infinity may be used to show the
  rest of the items and 0 may be used to disallow the "Show More" toggle.
 */
const ExpandableCardLayout = ({
  children,
  showRows,
  showMoreRows,
  showText,
  hideText,
  columns,
}) => {
  const [showMore, setShowMore] = useState(false)

  function getMaxVisibleCards(showAll) {
    const showAdditionalRows = showAll && showMoreRows > 0 ? showMoreRows : 1
    const width = window.innerWidth
    if (width > 1360) return (columns || 4) * showRows * showAdditionalRows
    if (width > 1100) return (columns || 3) * showRows * showAdditionalRows
    if (width > 572) return (columns || 2) * showRows * showAdditionalRows
    return 1 * showRows * showAdditionalRows
  }

  if (!children) return null // check children count is a must, or cause crash

  const maxVisibleCards = getMaxVisibleCards(showMore)
  const visibleChildren = children.slice(0, maxVisibleCards)

  return (
    <Fragment>
      <div className={`card-layout ${columns === 1 ? 'full-width' : ''}`}>
        {visibleChildren.map((child) => child)}
      </div>

      <ShowMoreToggle
        show={showMore}
        onClickHandler={() => setShowMore(!showMore)}
        showText={showText}
        hideText={hideText}
      />
    </Fragment>
  )
}

ExpandableCardLayout.propTypes = {
  children: arrayOf(node).isRequired,
  showRows: number,
  showMoreRows: number,
  showText: string,
  hideText: string,
  columns: number,
}

ExpandableCardLayout.defaultProps = {
  showRows: Infinity,
  showMoreRows: 0,
  showText: undefined,
  hideText: undefined,
  columns: null,
}

export default ExpandableCardLayout
