import React from 'react'
import { useSelector } from 'react-redux'
import './ItineraryAndAnnouncementsContainer.scss'
import ExpandableCardLayout from '../../components/ExpandableCardLayout/ExpandableCardLayout'
import AnnouncementCard from '../../components/AnnouncementCard/AnnouncementCard'
import announcementsIcon from '../../assets/icons/menu-announcements-icon.svg'

import { selectAnnouncementsArray } from '../../redux/announcements/announcementsSelectors'

/* removed Itinerary updates temporarily as per Jira issue: RCL220-94
import { selectItineraryUpdatesReverseChronologicalArray } from '../../redux/itineraryUpdates/itineraryUpdatesSelectors'
 */

const ItineraryAndAnnouncementsContainer = () => {
  const announcements = useSelector(selectAnnouncementsArray)

  /* removed Itinerary updates temporarily as per Jira issue: RCL220-94
  const itineraryUpdates = useSelector(
    selectItineraryUpdatesReverseChronologicalArray
  )
   */

  // const pageTitle = 'Itinerary Updates'
  const pageTitle = 'Announcements' // changed to Announcements temporarily as per Jira issue: RCL220-94

  return (
    <div className="announcements-container">
      <header className="desktop">
        <div className="title-row">
          <span className="title">{pageTitle}</span>
        </div>
      </header>
      <header className="mobile">
        <div className="title-row">
          <img src={announcementsIcon} alt="Announcements" />
          <div className="title">{pageTitle}</div>
        </div>
      </header>
      <main>
        {/* hide Itinerary section temporarily as per Jira issue: RCL220-94
        <ExpandableCardLayout
          columns={1}
          showRows={1}
          showMoreRows={Infinity}
          showText="Show Older"
          hideText="Hide Older"
        >
          {itineraryUpdates.map((item, index) => (
            <AnnouncementCard announcement={item} key={index} />
          ))}
        </ExpandableCardLayout>

        <h1 className="title">Latest Announcements</h1>
        */}

        <ExpandableCardLayout
          columns={1}
          showRows={4}
          showMoreRows={Infinity}
          showText="Show Older"
          hideText="Hide Older"
        >
          {announcements.map((item, index) => (
            <AnnouncementCard announcement={item} key={index} />
          ))}
        </ExpandableCardLayout>
      </main>
    </div>
  )
}

export default ItineraryAndAnnouncementsContainer
