import { createSelector } from 'reselect'
import { selectSidebarViewResource } from '../view/viewSelectors'
import { selectShipsIndexed } from '../ships/shipsSelectors'
import { selectBrandsIndexed } from '../brands/brandsSelectors'
import { selectRegionsIndexed } from '../regions/regionsSelectors'
import { selectPackages } from '../sailings/sailingsSelectors'
import { getSailsTo } from '../../utilities/dataUtils'
import parseValidHtmlString from '../../utilities/content/parseValidHtmlString'
// import { BrandCode } from '../../configuration/constants'

// NOTE: these constants may end up coming from the feed directly, meaning we can get rid of them
// const ALERT_TITLE = 'Healthy Cruising Protocols'

// const BRAND_ROYAL_CARRIBEAN_ALERT = `<p>As we return to sea, your clients' health and safety remains our top priority.
// We've partnered with expert medical and scientific minds to guide us in the development of comprehensive protocols
// to protect your clients and the ones they love. And we're working with local health authorities in our homeport
// countries to ensure our guests meet current inbound travel requirements. We're continually evaluating these
// protocols and will make updates as public health standards evolve.</p><p>Please <a href="https://www.healthycruising.com/royal-caribbean-the-royal-promise/" target="_blank">click here</a> to visit our Healthy Cruising
// hub for the latest information on vaccination requirements as well as other health and safety protocols.</p>`

// const BRAND_CELEBRITY_ALERT = `<p>From bow to stern, we're taking extraordinary measures to keep you Healthy at Sea.
// We're sailing at reduced capacity to provide more space for more luxury. We're with you all the way, including safe
// passage home by private air transfer, if needed*</p>
// <p>Please <a href="https://www.healthycruising.com/celebrity-healthy-at-sea/" target="_blank">click here</a> to visit our Healthy Cruising hub for the latest information on
// vaccination requirements as well as other health and safety protocols.</p>`

const selectShipViewContent = createSelector(
  selectShipsIndexed,
  selectBrandsIndexed,
  selectRegionsIndexed,
  selectPackages,
  selectSidebarViewResource,
  (ships, brands, regions, packages, shortCode) => {
    const ship = ships[shortCode]

    if (!ship) {
      return null // should be unreachable
    }

    const brand = brands[ship.brand_code]
    const sailsTo = getSailsTo(regions, packages, shortCode)

    const result = {
      name: ship.name,
      location: 'UNAVAILABLE',
      sailsTo,
      brand: brand?.name || '',
      shipClass: ship.class,
      bio: parseValidHtmlString(ship.body),
      imageSrc: ship.image[0].file,
    }

    // switch (ship.brand_code) {
    //   case BrandCode.CELEBRITY:
    //     result.alertMessage = {
    //       title: ALERT_TITLE,
    //       body: BRAND_CELEBRITY_ALERT,
    //     }
    //     break
    //   case BrandCode.ROYAL_CARRIBBEAN:
    //     result.alertMessage = {
    //       title: ALERT_TITLE,
    //       body: BRAND_ROYAL_CARRIBEAN_ALERT,
    //     }
    //     break
    //   default:
    //     result.alertMessage = null
    //     break
    // }

    return result
  }
)

export default selectShipViewContent
