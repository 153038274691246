export const isValidPackageForDepartureMonth = (
  packageId,
  packageInfo,
  sailings,
  departureMonth
) => {
  const monthsToCheck = departureMonth
    ? [departureMonth]
    : Object.keys(sailings)
  const isValid = !!monthsToCheck.find((month) => {
    const shipMonthSailings = sailings[month][packageInfo.shipCode]
    if (!shipMonthSailings) {
      return false
    }
    return shipMonthSailings.find((x) => x.packageId === packageId)
  })
  return isValid
}

export const getSailsTo = (
  regions,
  packages,
  shipCode,
  departurePort = null
) => {
  const regionShortCodes = new Set()
  Object.values(packages).forEach((packageInfo) => {
    if (
      packageInfo.shipCode === shipCode &&
      (!departurePort || departurePort === packageInfo.departurePort)
    ) {
      regionShortCodes.add(packageInfo.destinationCode)
    }
  })
  return [...regionShortCodes].map((regionCode) => regions[regionCode]?.name)
}
