import React from 'react'
import { string, func, arrayOf, shape, oneOfType, number } from 'prop-types'

const FilterSection = ({
  title,
  updateSelections,
  selectedOptions,
  options,
  type,
}) => {
  return (
    <div className={`filters-popout__content__section section-${type}`}>
      <h3>{title}</h3>
      <div className="filters-popout__content__section__options">
        {options.map((option, key) => (
          <label
            htmlFor={`option-${String(option.value)}`}
            className="filters-popout__content__section__options__option"
            key={key}
          >
            <input
              type="checkbox"
              id={`option-${String(option.value)}`}
              name={`option-${String(option.value)}`}
              className="filters-popout__content__section__options__option__input"
              value={option.value}
              onChange={() => {
                const newOptions = [...selectedOptions]
                const optionIndex = selectedOptions.indexOf(option.value)
                if (optionIndex !== -1) {
                  newOptions.splice(optionIndex, 1)
                } else {
                  newOptions.push(option.value)
                }
                updateSelections(newOptions)
              }}
              checked={selectedOptions.indexOf(option.value) !== -1}
            />
            <span className="filters-popout__content__section__options__option__label">
              {option.label}
            </span>
          </label>
        ))}
      </div>
    </div>
  )
}

FilterSection.propTypes = {
  title: string.isRequired,
  updateSelections: func.isRequired,
  selectedOptions: arrayOf(oneOfType([string, number])),
  options: arrayOf(
    shape({
      value: oneOfType([string, number]).isRequired,
      label: string.isRequired,
    })
  ),
  type: string.isRequired,
}

FilterSection.defaultProps = {
  selectedOptions: [],
  options: [],
}

export default FilterSection
