import React from 'react'
import { objectOf, func, string } from 'prop-types'
import './BrandAndShipClassFilter.scss'
import SelectWithLabel from '../SelectWithLabel/SelectWithLabel'

const BrandAndShipClassFilter = ({
  brands,
  shipClasses,
  selectedBrand,
  selectedShipClass,
  onNewBrand,
  onNewShipClass,
}) => {
  return (
    <div className="list-filters-wrapper">
      <div className="list-filters">
        <SelectWithLabel
          placeholder="All Brands"
          label="Brand"
          values={brands}
          selectedValue={selectedBrand}
          onChange={onNewBrand}
        />
        <SelectWithLabel
          placeholder="All Classes"
          label="Ship Class"
          values={shipClasses}
          selectedValue={selectedShipClass}
          onChange={onNewShipClass}
        />
      </div>
    </div>
  )
}

BrandAndShipClassFilter.propTypes = {
  brands: objectOf(Object).isRequired,
  shipClasses: objectOf(Object).isRequired,
  selectedBrand: string,
  selectedShipClass: string,
  onNewBrand: func.isRequired,
  onNewShipClass: func.isRequired,
}

BrandAndShipClassFilter.defaultProps = {
  selectedBrand: null,
  selectedShipClass: null,
}

export default BrandAndShipClassFilter
