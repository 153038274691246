import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { func } from 'prop-types'

import MapAndScheduleMenuItem from '../../components/MapAndScheduleMenuItem/MapAndScheduleMenuItem'
import regionsIcon from '../../assets/icons/menu-regions-icon.svg'

import selectRegionListData from '../../redux/selectors/selectRegionListData'
import {
  setViewOrigin,
  zoomToAndCloseSidebar,
} from '../../redux/view/viewActions'
import { ZoomLevel } from '../../configuration/constants'

import './RegionsContainer.scss'

const RegionsContainer = ({ swapView }) => {
  const dispatch = useDispatch()
  const regions = useSelector(selectRegionListData)
  const handleScheduleClick = (region) => {
    dispatch(setViewOrigin('Regions'))
    swapView('region', region.shortCode)
  }
  return (
    <div className="regions-container">
      <header>
        <div className="title-row">
          <img src={regionsIcon} alt="" />
          <div className="label">Regions</div>
          <div />
        </div>
      </header>
      <main>
        {regions.map((region) => {
          return (
            <MapAndScheduleMenuItem
              key={region.shortCode}
              name={region.name}
              onClickSchedule={() => handleScheduleClick(region)}
              onClickViewMap={() =>
                dispatch(
                  zoomToAndCloseSidebar(ZoomLevel.REGION, region.shortCode)
                )
              }
              hideViewOnMap={!region.enableViewOnMap}
            />
          )
        })}
      </main>
    </div>
  )
}

RegionsContainer.propTypes = {
  swapView: func.isRequired,
}

export default RegionsContainer
