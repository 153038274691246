import { actionTypes } from './sailingsActions'

const initialState = {
  loading: false,
  error: null,
  data: {
    sailings: {},
    packages: {},
  },
}

function sailingsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_START:
      return { ...state, loading: true, error: false }
    case actionTypes.FETCH_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: false }
    case actionTypes.FETCH_ERROR:
      return { ...state, loading: false, error: true }
    default:
      return state
  }
}

export default sailingsReducer
