import React from 'react'
import { func, string, shape, bool } from 'prop-types'

const PinItem = ({ updateFilters, pin, isActive }) => (
  <li
    className={`component-pin-filters__pins__pin ${
      isActive ? 'active' : 'inactive'
    }`}
  >
    <button
      className={`component-pin-filters__pins__pin__button ${
        isActive ? 'active' : 'inactive'
      }`}
      onClick={() => updateFilters(pin.type)}
    >
      <span className="component-pin-filters__pins__pin__label">
        {pin.label}
      </span>
    </button>
  </li>
)

PinItem.propTypes = {
  updateFilters: func.isRequired,
  pin: shape({
    type: string.isRequired,
    label: string.isRequired,
    icon: string.isRequired,
  }).isRequired,
  isActive: bool,
}

PinItem.defaultProps = {
  isActive: false,
}

export default PinItem
