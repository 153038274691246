import React, { useEffect } from 'react'
import ReactModal from 'react-modal'
import { useDispatch } from 'react-redux'
import { useUI } from '../../context/modalContext'
import { modalIds } from '../../context/configuration'
import { openSidebarView, setViewOrigin } from '../../redux/view/viewActions'
import LandingModal from '../../components/LandingModal/LandingModal'
import './Modals.scss'

/**
 * Modals Manager
 * all the modals in this application could be here.
 *
 * @returns active modal
 */
const Modals = () => {
  const dispatch = useDispatch()
  const { displayModal, modalView, openModal, closeModal } = useUI()

  // default open landing modal
  useEffect(() => {
    openModal(modalIds.LANDING_MODAL)
  }, [])

  const handleSearchResult = (type, shortCode) => {
    closeModal()
    dispatch(setViewOrigin('Widget'))
    dispatch(openSidebarView(type, shortCode))
  }

  return (
    <ReactModal
      className="modal modal-content"
      overlayClassName="modal-overlay overlay-higher"
      isOpen={displayModal}
      onRequestClose={closeModal}
    >
      {modalView === modalIds.LANDING_MODAL && (
        <LandingModal handleSelection={handleSearchResult} />
      )}
    </ReactModal>
  )
}

export default Modals
