import React from 'react'
import { bool, string } from 'prop-types'

const DeploymentTableHead = ({
  hasDeployments,
  isMobile,
  isShipVariant,
  nameTitle,
}) => {
  const noDeoplymentsFound =
    'No Sailings Found. Please modify your search criteria and try again.'

  // no data and in mobile
  if (!hasDeployments && isMobile) {
    return <h2 className="text-center no-data-message">{noDeoplymentsFound}</h2>
  }
  // have data still in mobile, nothing to show
  if (isMobile) return null

  // no data and in desktop
  if (!hasDeployments) {
    return (
      <thead>
        <tr>
          <th className="text-center no-data-message">{noDeoplymentsFound}</th>
        </tr>
      </thead>
    )
  }

  const variantColumn = isShipVariant ? 'Ship' : 'Region'

  // have data and in desktop
  return (
    <thead>
      <tr>
        <th>{nameTitle || 'Name'}</th>
        <th>Sail Date(s)</th>
        <th>{variantColumn}</th>
        <th>Departure Port</th>
        <th>Ports of Call</th>
        <th></th>
      </tr>
    </thead>
  )
}

DeploymentTableHead.defaultProps = {
  isMobile: false,
  isShipVariant: false,
  nameTitle: null,
}

DeploymentTableHead.propTypes = {
  hasDeployments: bool.isRequired,
  isMobile: bool,
  isShipVariant: bool,
  nameTitle: string,
}

export default DeploymentTableHead
