import 'react-app-polyfill/ie11' //  eslint-disable-line
import 'react-app-polyfill/stable' // eslint-disable-line
import 'core-js'
import 'airbnb-browser-shims'

import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import App from './containers/App/App'
import configureStore from './redux/store'

import './styles/base/base.scss'
import './styles/base/buttons.scss'
import './styles/base/forms.scss'
import './styles/base/modal.scss'
import './styles/base/typography.scss'
import './styles/base/utilities/border.scss'
import './styles/base/utilities/colors.scss'
import './styles/base/utilities/containers.scss'
import './styles/base/utilities/layout.scss'
import './styles/base/utilities/text.scss'

import './styles/base/print.scss'

const store = configureStore()

const renderApp = () =>
  render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
    document.getElementById('rcl__deployment-hub')
  )

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./containers/App/App', renderApp)
}

renderApp()
