import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './PortContainer.scss'

import { PinTypeName } from '../../configuration/constants'
import AlertNotice from '../../components/AlertNotice/AlertNotice'
import ClickthroughDetails from '../../components/ClickthroughDetails/ClickthroughDetails'
import ClickthroughHeader from '../../components/ClickthroughHeader/ClickthroughHeader'
import DeploymentTable from '../../components/DeploymentTable/DeploymentTable'
import ShowMoreToggle from '../../components/ShowMoreToggle/ShowMoreToggle'

import selectPortViewContent from '../../redux/selectors/selectPortViewContent'
import { selectSidebarPinType } from '../../redux/view/viewSelectors'
import selectPortViewDeployments from '../../redux/selectors/selectPortViewDeployments'
import selectPortViewDeploymentsFilterOptions from '../../redux/selectors/selectPortViewDeploymentsFilterOptions'
import { selectFilterableYears } from '../../redux/sailings/sailingsSelectors'
import { selectPortDeploymentsFilter } from '../../redux/filters/filtersSelectors'
import { setPortDeploymentsFilter } from '../../redux/filters/filtersActions'
import { getPortDefaultFilterOptions } from '../../redux/ports/portUtils'

import homePortPin from '../../assets/icons/port-pin-shadow.svg'
import callPortPin from '../../assets/icons/port-of-call-pin-shadow.svg'

function generateDetailsComponentParam(content) {
  return {
    imageSrc: content?.imageSrc,
    sections: [
      {
        type: 'info',
        label: 'Visiting Ships',
        value: content?.visitingShips,
      },
      {
        type: 'bio',
        label: 'Destination Bio',
        value: content?.bio,
      },
    ],
  }
}

const PortContainer = () => {
  const dispatch = useDispatch()
  const content = useSelector(selectPortViewContent)
  const alertMessage = content?.alertMessage
  const [showDestinationInformation, setShowDestinationInformation] =
    useState(false)
  const deployments = useSelector(selectPortViewDeployments)
  const years = useSelector(selectFilterableYears)
  const filter = useSelector(selectPortDeploymentsFilter)
  const filterOptions = useSelector(selectPortViewDeploymentsFilterOptions)
  const pinType = useSelector(selectSidebarPinType)
  const portPin = pinType === PinTypeName.HOME_PORT ? homePortPin : callPortPin

  useEffect(() => {
    dispatch(setPortDeploymentsFilter(getPortDefaultFilterOptions()))
  }, [])

  const clickthroughHeader = (
    <ClickthroughHeader
      pin={<img src={portPin} alt="" />}
      title={content?.name}
    />
  )

  const filterChangeHandler = (updates) => {
    dispatch(setPortDeploymentsFilter(updates))
  }

  return (
    <div className="region-container">
      <header className="mobile">
        <div className="title-row">
          <img src={portPin} alt="" />
          <div className="title">Ports</div>
        </div>
      </header>
      <header className="desktop">{clickthroughHeader}</header>
      <main>
        <div className="mobile">{clickthroughHeader}</div>
        {alertMessage && (
          <div className="alert-wrapper">
            <AlertNotice {...alertMessage} />
          </div>
        )}
        <div className="details-section">
          <div className="mobile show-additional-information">
            <div className="title">Destination Information</div>
            <ShowMoreToggle
              show={showDestinationInformation}
              onClickHandler={() =>
                setShowDestinationInformation(!showDestinationInformation)
              }
            />
          </div>
          <div
            className={`additional-information ${
              showDestinationInformation ? '' : 'hidden'
            }`}
          >
            <ClickthroughDetails {...generateDetailsComponentParam(content)} />
          </div>
        </div>

        <DeploymentTable
          deployments={deployments}
          years={years}
          filter={filter}
          filterSections={filterOptions}
          updateFilters={filterChangeHandler}
          icon={portPin}
          isShipVariant
        />
      </main>
    </div>
  )
}

export default PortContainer
