import { createSelector } from 'reselect'

const getAnnouncementsState = (state) => state.announcements

export const selectAnnouncements = createSelector(
  getAnnouncementsState,
  (state) => {
    return state.data || {}
  }
)

export const selectAnnouncementsArray = createSelector(
  selectAnnouncements,
  (announcements) =>
    Object.values(announcements || {}).map((item) => ({
      isNew: !!item.new,
      date: item.date,
      url: item.link || '',
      title: item.title,
      shortDescription: item.short_description || '',
    }))
)
