/* eslint-disable react/prop-types */
import React, { Fragment } from 'react'
// import { string, arrayOf, oneOf, oneOfType, shape } from 'prop-types'

import { mapNewlinesAsBreaks } from '../../utilities/rendering/renderUtils'

import './ClickthroughDetails.scss'

/* 
  NOTE(ebarrett): Details section at top of Port, Ship, and Region containers
    all have similar layouts / styling, with minor differences in the content,
    comprising an image on the right, with text details on the left

    Possible left-side sub-section layouts are...
      - 'row'  -> Four-wide label + value, small text ('row')
      - 'info' -> Single label + value, small text
      - 'bio'  -> Bio with header

    USAGE: Pass an array of objects to this component, as well as an imageSrc
    Objects in this array can have the following forms...

      { type: 'info', label, value }
      { type: 'bio', label, value } 
      { type: 'row', items: [{label, value}, {label, value}, {label, value}, {label, value}] }
*/

const ClickthroughDetails = ({ sections, imageSrc }) => {
  const renderFunctions = {
    info: ({ label, value }) => {
      return (
        <div>
          <div className="info-title">{label}</div>
          <div className="info-content">{value}</div>
        </div>
      )
    },
    row: ({ items }) => {
      return (
        <div className="overview-row">
          {items.map(({ label, value }, index) => (
            <div className="item" key={`item_${index}`}>
              <div className="title">{label}</div>
              <div>{value}</div>
            </div>
          ))}
        </div>
      )
    },
    bio: ({ label, value }) => {
      if (!value) {
        return null
      }
      return (
        <article>
          <div className="bio-title">{label}</div>
          <div className="bio-content">{mapNewlinesAsBreaks(value)}</div>
        </article>
      )
    },
  }

  return (
    <div className="clickthrough-details">
      <div>
        {sections.map((section, index) => {
          return renderFunctions[section?.type] ? (
            <Fragment key={`sect_${index}`}>
              {renderFunctions[section.type](section)}
            </Fragment>
          ) : null
        })}
      </div>
      <div
        className="landscape-image"
        style={{ backgroundImage: `url(${imageSrc})` }}
      />
    </div>
  )
}

// const infoSchema = shape({
//   type: oneOf(['info']).isRequired,
//   label: string.isRequired,
//   value: string.isRequired
// })
//
// const rowSchema = shape({
//   type: oneOf(['row']).isRequired,
//   items: arrayOf(shape({
//     label: string.isRequired,
//     value: string.isRequired
//   }))
// })
//
// const bioSchema = shape({
//   type: oneOf(['bio']).isRequired,
//   label: string.isRequired,
//   value: string.isRequired
// })
//
// ClickthroughDetails.propTypes = {
//   imageSrc: string.isRequired,
//   sections: arrayOf(oneOfType([infoSchema, rowSchema, bioSchema])).isRequired
// }

export default ClickthroughDetails
