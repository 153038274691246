// Helper functions
function getBoxWithReflection(box) {
  return {
    right: box.right,
    left: box.left - box.width,
    top: box.top,
    bottom: box.bottom,
    width: 2 * box.width,
    height: box.height,
  }
}

function getReflectedBox(box) {
  return {
    right: box.left,
    left: box.left - box.width,
    top: box.top,
    bottom: box.bottom,
    width: box.width,
    height: box.height,
  }
}

function arrayAddWithCarry(array, index) {
  if (index >= 0) {
    if (array[index]) {
      arrayAddWithCarry(array, index - 1)
    }
    // eslint-disable-next-line
    array[index] = !array[index]
  }
}

function getIntersectionArea(boxA, boxB) {
  const left = Math.max(boxA.left, boxB.left)
  const right = Math.min(boxA.right, boxB.right)
  const bottom = Math.min(boxA.bottom, boxB.bottom)
  const top = Math.max(boxA.top, boxB.top)
  if (left < right && bottom > top) {
    const overlap = (right - left) * (bottom - top)
    return overlap
  }
  return 0
}

function checkCollision(boxA, boxB) {
  if (boxA === boxB || boxA.width === 0 || boxB.width === 0) {
    return false
  }
  return getIntersectionArea(boxA, boxB) !== 0
}

// Flip status algorithm
export function getMarkerFlipStatusFromBoundingBoxes(boundingBoxes) {
  const doFlip = new Array(boundingBoxes.length).fill(-1)

  const fullBoxes = boundingBoxes.map(getBoxWithReflection)
  const leftBoxes = boundingBoxes.map(getReflectedBox)
  const rightBoxes = [...boundingBoxes]

  // 1. If no full overlap at all, or no width, no flip
  fullBoxes.forEach((boxA, indexA) => {
    let collides = false
    fullBoxes.forEach((boxB) => {
      collides = collides || checkCollision(boxA, boxB)
    })
    if (!collides) {
      doFlip[indexA] = false
    }
  })

  // 2. If only left intersection, no flip
  rightBoxes.forEach((boxA, indexA) => {
    if (typeof doFlip[indexA] === 'boolean') {
      return
    }
    let collides = false
    for (let indexB = 0; indexB < fullBoxes.length; indexB++) {
      if (indexA !== indexB) {
        const boxB =
          doFlip[indexB] === -1 ? fullBoxes[indexB] : rightBoxes[indexB]
        collides = collides || checkCollision(boxA, boxB)
      }
    }
    if (!collides) {
      doFlip[indexA] = false
    }
  })

  // 3. If only right intersection, flip
  leftBoxes.forEach((boxA, indexA) => {
    if (typeof doFlip[indexA] === 'boolean') {
      return
    }
    let collides = false
    for (let indexB = 0; indexB < fullBoxes.length; indexB++) {
      if (indexA !== indexB) {
        let boxB
        switch (doFlip[indexB]) {
          case true:
            boxB = leftBoxes[indexB]
            break
          case false:
            boxB = rightBoxes[indexB]
            break
          default:
            boxB = fullBoxes[indexB]
            break
        }
        collides = collides || checkCollision(boxA, boxB)
      }
    }
    if (!collides) {
      doFlip[indexA] = true
    }
  })

  // 4. For remaining boxes, minimize intersection area
  const remainingLabelIndices = []
  doFlip.forEach((x, i) => {
    if (typeof x !== 'boolean') {
      remainingLabelIndices.push(i)
    }
  })
  /* 
    TODO(ebarrett): Checking for remaining set being less than 10 is a performance
      compromise... This algorithm is O(n^2 * 2^n), so the browser can stall for large
      input sets. When there are too many labels intersecting to perform this quickly,
      we'll just deal with it being suboptimal.

      Input size 5 -> 320 checks
      Input size 6 -> 975 checks
      ...
      Input size 10 -> 46080 checks

      A better solution would be to identify the clusters of labels and run this algorithm
      on each cluster. If there are two clusters of 5 labels that don't impact each other,
      it's better for us to do 975 checks twice than 46080 checks once.
  */
  if (remainingLabelIndices.length < 10) {
    const flipAttempt = remainingLabelIndices.map(() => false)
    let bestTotalIntersection = Infinity
    let bestFlipOrder = [...flipAttempt]
    while (flipAttempt.indexOf(false) > -1) {
      let currentTotalIntersection = 0
      for (let indexA = 0; indexA < flipAttempt.length - 1; indexA++) {
        for (let indexB = indexA + 1; indexB < flipAttempt.length; indexB++) {
          const boxAIndex = remainingLabelIndices[indexA]
          const boxBIndex = remainingLabelIndices[indexB]
          const boxA = flipAttempt[indexA]
            ? leftBoxes[boxAIndex]
            : rightBoxes[boxAIndex]
          const boxB = flipAttempt[indexB]
            ? leftBoxes[boxBIndex]
            : rightBoxes[boxBIndex]
          if (checkCollision(boxA, boxB)) {
            currentTotalIntersection += getIntersectionArea(boxA, boxB)
          }
        }
      }
      if (currentTotalIntersection < bestTotalIntersection) {
        bestTotalIntersection = currentTotalIntersection
        bestFlipOrder = [...flipAttempt]
      }
      arrayAddWithCarry(flipAttempt, flipAttempt.length - 1)
    }

    remainingLabelIndices.forEach((labelIndex, flipIndex) => {
      doFlip[labelIndex] = bestFlipOrder[flipIndex]
    })
  }

  return doFlip
}
