import { createSelector } from 'reselect'
import { selectSidebarViewResource } from '../view/viewSelectors'
import { selectRegionsIndexed } from '../regions/regionsSelectors'
import { selectPackages } from '../sailings/sailingsSelectors'
import { selectPortsIndexed } from '../ports/portsSelectors'
import { selectShipsIndexed } from '../ships/shipsSelectors'
import parseValidHtmlString, {
  unescapeEncodedString,
} from '../../utilities/content/parseValidHtmlString'

// these may come from a feed at some point
// const REGION_ALERT_TITLE = 'Healthy Cruising Protocols'
// const REGION_ALERT_MESSAGE = `<p>As we return to sea, your clients' health and safety remains our top priority.
// We've partnered with expert medical and scientific minds to guide us in the development of comprehensive protocols
// to protect your clients and the ones they love. And we're working with local health authorities in our homeport
// countries to ensure our guests meet current inbound travel requirements. We're continually evaluating these protocols
// and will make updates as public health standards evolve.</p>
// <p>Please <a href="https://www.healthycruising.com/" target="_blank">click here</a> to visit our Healthy Cruising hub
// for the latest information on vaccination requirements as well as other health and safety protocols.</p>`

const selectRegionViewContent = createSelector(
  selectRegionsIndexed,
  selectSidebarViewResource,
  selectPackages,
  selectShipsIndexed,
  selectPortsIndexed,
  (regions, shortCode, packages, ships, ports) => {
    const region = regions[shortCode]

    if (!region) {
      return null // should be unreachable
    }

    // Determine visiting ships, ports of call
    const visitingShipCodes = new Set()
    const portOfCallCodes = new Set()
    Object.values(packages).forEach((packageInfo) => {
      if (packageInfo.destinationCode === shortCode) {
        visitingShipCodes.add(packageInfo.shipCode)
        packageInfo.ports.forEach((portCode) => portOfCallCodes.add(portCode))
      }
    })
    const visitingShips = [...visitingShipCodes]
      .map((shipCode) => ships[shipCode].name)
      .join(', ')

    const portsOfCall = [...portOfCallCodes]
      .map((portCode) => {
        if (ports[portCode]) {
          return unescapeEncodedString(ports[portCode].name)
        } else {
          return `MISSING ${portCode}`
        }
      })
      .sort()
      .join(' • ')

    return {
      name: region.name,
      imageSrc: region.image[0].file,
      visitingShips,
      portsOfCall,
      bio: region?.body_long
        ? parseValidHtmlString(region.body_long)
        : parseValidHtmlString(region.body),
      // alertMessage: {
      //   title: REGION_ALERT_TITLE,
      //   body: REGION_ALERT_MESSAGE,
      // },
    }
  }
)

export default selectRegionViewContent
