import React from 'react'
import { bool, string, func } from 'prop-types'
import './ShowMoreToggle.scss'
import chevronDown from '../../assets/icons/chevron-down.svg'

const ShowMoreToggle = ({ show, onClickHandler, showText, hideText }) => {
  return (
    <button
      type="button"
      className={`basic-link show-more-button ${show ? 'showing' : ''}`}
      onClick={onClickHandler}
    >
      {show ? hideText : showText}
      <img src={chevronDown} alt="" />
    </button>
  )
}

ShowMoreToggle.propTypes = {
  show: bool.isRequired,
  onClickHandler: func.isRequired,
  showText: string,
  hideText: string,
}

ShowMoreToggle.defaultProps = {
  showText: 'Show',
  hideText: 'Hide',
}

export default ShowMoreToggle
