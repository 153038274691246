import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactModal from 'react-modal'
import queryString from 'query-string'
import {
  fetchConfiguration,
  selectConfigurationData,
} from '../../redux/configuration/configuration'
import { fetchBrands } from '../../redux/brands/brandsActions'
import { fetchShips } from '../../redux/ships/shipsActions'
import { fetchRegions } from '../../redux/regions/regionsActions'
import { fetchSailings } from '../../redux/sailings/sailingsActions'
import { fetchPorts } from '../../redux/ports/portsActions'
import { fetchAnnouncements } from '../../redux/announcements/announcementsActions'
import { fetchItineraryUpdates } from '../../redux/itineraryUpdates/itineraryUpdatesActions'

import { UIProvider } from '../../context/modalContext'
import Modals from '../Modals/Modals'
import MapContainer from '../Map/MapContainer'
import SideBar from '../SideBar/SideBar'
import { fetchSso } from '../../redux/authentication/authentication'
import analyticsPageView from '../../utilities/analytics/analyticsPageView'

if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#rcl__deployment-hub')
}

function App() {
  const configuration = useSelector(selectConfigurationData)
  const dispatch = useDispatch()

  useEffect(() => {
    // doing it this way rather then only on load to prevent having to wait when using hot reload.
    if (!configuration.data && !configuration.loading && !configuration.error) {
      const params = queryString.parse(window.location.search)
      const ssoParam = params.sso || params.z || params.cptok

      if (!ssoParam) {
        window.location.href = process.env.REACT_APP_CRUISING_POWER_LOGIN
        return
      }

      dispatch(fetchSso(ssoParam)).then(analyticsPageView)
      dispatch(fetchConfiguration())
      dispatch(fetchBrands())
      dispatch(fetchShips())
      dispatch(fetchRegions())
      dispatch(fetchSailings())
      dispatch(fetchPorts())
      dispatch(fetchAnnouncements())
      dispatch(fetchItineraryUpdates())
    }
  }, [dispatch, configuration])

  return (
    <div className="rcl__deployment-hub" data-testid="rcl__deployment-hub">
      <link rel="stylesheet" href="https://use.typekit.net/ywr6egt.css" />
      <UIProvider>
        <SideBar />
        <MapContainer />
        <Modals />
      </UIProvider>
    </div>
  )
}

export default App
