import { actionTypes } from './shipsActions'

const initialState = {
  loading: false,
  error: null,
  data: null,
  brandFilter: '',
  classFilter: '',
}

function shipsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_START:
      return { ...state, loading: true, error: false }
    case actionTypes.FETCH_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: false }
    case actionTypes.FETCH_ERROR:
      return { ...state, loading: false, error: true }
    case actionTypes.SET_BRAND_FILTER:
      return { ...state, brandFilter: action.payload }
    case actionTypes.SET_CLASS_FILTER:
      return { ...state, classFilter: action.payload }
    default:
      return state
  }
}

export default shipsReducer
