import React from 'react'
import { string } from 'prop-types'

import alertIcon from '../../assets/icons/alert.svg'

import './AlertNotice.scss'

const AlertNotice = ({ title, body }) => {
  return (
    <div className="component-alert">
      <div className="icon-wrapper">
        <img src={alertIcon} alt="" />
      </div>
      <div className="content-wrapper">
        <div className="title">{title}</div>
        {/* eslint-disable-next-line */}
        <div className="body" dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </div>
  )
}

AlertNotice.propTypes = {
  title: string.isRequired,
  body: string.isRequired,
}

export default AlertNotice
