import { createSelector } from 'reselect'
import { uniq } from 'lodash'

import { selectPortsIndexed } from '../ports/portsSelectors'
import { selectZoomLevel, selectZoomResource } from '../view/viewSelectors'
import { ZoomLevel } from '../../configuration/constants'
import { selectPackages, selectSailings } from '../sailings/sailingsSelectors'
import { selectDepartureMonth } from '../filters/filtersSelectors'
import { selectShipsIndexed } from '../ships/shipsSelectors'
import { selectRegionsIndexed } from '../regions/regionsSelectors'
import {
  isValidPackageForDepartureMonth,
  getSailsTo,
} from '../../utilities/dataUtils'

const selectBrandPinData = createSelector(
  selectPortsIndexed,
  selectZoomLevel,
  selectZoomResource,
  selectSailings,
  selectPackages,
  selectDepartureMonth,
  selectShipsIndexed,
  selectRegionsIndexed,
  (
    ports,
    zoomLevel,
    zoomResource,
    sailings,
    packages,
    departureMonth,
    ships,
    regions
  ) => {
    if (zoomLevel !== ZoomLevel.PORT) {
      return []
    }

    const port = ports[zoomResource]

    const brandShips = {}
    const brandDates = {}
    Object.entries(packages || {}).forEach(([packageId, packageInfo]) => {
      if (
        packageInfo.departurePort === port.port_code &&
        isValidPackageForDepartureMonth(
          packageId,
          packageInfo,
          sailings,
          departureMonth
        )
      ) {
        const { brand } = packageInfo
        if (!brandShips[brand]) {
          brandShips[brand] = new Set()
        }
        if (!brandDates[brand]) {
          brandDates[brand] = []
        }
        Object.keys(sailings).forEach((yearMonth) => {
          Object.keys(sailings[yearMonth]).forEach((shipCode) => {
            sailings[yearMonth][shipCode].forEach((shipSailing) => {
              if (packageId === shipSailing.packageId) {
                brandShips[brand].add(shipCode)
                brandDates[brand].push(shipSailing.sailDate)
              }
            })
          })
        })
      }
    })
    const locations = port.deployment_map_locations
    const locationData =
      locations
        ?.map(({ location, brand }) => {
          const portBrandShips = brandShips[brand.brand_code]

          let viewShips = []
          if (portBrandShips) {
            viewShips = [...portBrandShips].map((shipCode) => {
              const ship = ships[shipCode]
              const sailsTo = getSailsTo(
                regions,
                packages,
                shipCode,
                zoomResource
              )
              return {
                name: ship.name,
                shipCode: ship.ship_code,
                brandCode: ship.brand_code,
                sailsTo,
              }
            })
          }
          return {
            type: brand.post_name,
            lat: location.lat,
            lng: location.lng,
            ships: viewShips,
            brandCode: brand.brand_code,
            brands: [brand.brand_code],
            dates: uniq(brandDates[brand.brand_code] || []),
            port: {
              name: port?.name,
              portCode: port?.port_code,
            },
          }
        })
        .filter((pinData) => pinData.ships.length > 0) || []

    return locationData?.length > 1
      ? locationData.map((data) => ({ ...data, conflicts: true }))
      : locationData
  }
)

export default selectBrandPinData
