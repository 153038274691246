import React, { Fragment } from 'react'

export function mapNewlinesAsBreaks(inputString) {
  const parts = inputString.split('\n')
  return parts.map((part, index) => {
    return (
      <Fragment key={index}>
        {index > 0 && <br />}
        {part}
      </Fragment>
    )
  })
}
