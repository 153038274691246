import { getShips } from '../../api/api'

export const actionTypes = {
  FETCH_START: 'ships/FETCH_START',
  FETCH_SUCCESS: 'ships/FETCH_SUCCESS',
  FETCH_ERROR: 'ships/FETCH_ERROR',
  SET_BRAND_FILTER: 'ships/SET_BRAND_FILTER',
  SET_CLASS_FILTER: 'ships/SET_CLASS_FILTER',
}

export const fetchShips = () => async (dispatch) => {
  dispatch({ type: actionTypes.FETCH_START })

  try {
    const { data } = await getShips()
    return dispatch({ type: actionTypes.FETCH_SUCCESS, payload: data })
  } catch (error) {
    return dispatch({ type: actionTypes.FETCH_ERROR })
  }
}

export const setShipsBrandFilter = (brandCode) => (dispatch) => {
  dispatch({ type: actionTypes.SET_BRAND_FILTER, payload: brandCode })
}

export const setShipsClassFilter = (shipClass) => (dispatch) => {
  dispatch({ type: actionTypes.SET_CLASS_FILTER, payload: shipClass })
}
