import React from 'react'
import { shape, arrayOf, string, func } from 'prop-types'
import './DeploymentCard.scss'

import { BrandCode } from '../../configuration/constants'
import rcclBrand from '../../assets/images/royal-caribbean-logo.png'
import celebrityBrand from '../../assets/icons/celebrirty-cruises-logo-blue.svg'

const getBrandIcon = (brandCode) => {
  switch (brandCode) {
    case BrandCode.ROYAL_CARRIBBEAN:
      return rcclBrand
    case BrandCode.CELEBRITY:
      return celebrityBrand
    default:
      return null
  }
}

const DeploymentCard = ({ ship, onDetailsClick }) => (
  <div className="deployment-card card">
    {ship.image && <img src={ship.image} alt="" className="header-image" />}
    <div className="deployment-card-container">
      <div className="heading">
        <div className="title">{ship.name}</div>
        <img src={getBrandIcon(ship.brandCode)} alt="" className="logo" />
      </div>
      <div className="label">Sails To</div>
      <div className="sails-to">{ship.sailsTo.join(', ')}</div>
      <div className="link" onClick={() => onDetailsClick(ship.shortCode)}>
        <span>Deployment Details</span>
      </div>
    </div>
  </div>
)

DeploymentCard.propTypes = {
  ship: shape({
    name: string.isRequired,
    sailsTo: arrayOf(string).isRequired,
    shortCode: string.isRequired,
  }).isRequired,
  onDetailsClick: func.isRequired,
}

export default DeploymentCard
