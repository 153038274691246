import { createSelector } from 'reselect'

const getPortsState = (state) => state.ports

export const selectPorts = createSelector(
  getPortsState,
  (state) => state.data || []
)

export const selectPortsIndexed = createSelector(selectPorts, (ports) => {
  return ports.reduce((acc, cur) => {
    acc[cur.port_code] = cur
    return acc
  }, {})
})

export const selectPortListData = createSelector(selectPorts, (ports) => {
  return ports
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .map((port) => {
      return {
        name: port.name,
        shortCode: port.port_code,
        ships: [],
        itineraryCount: 0,
      }
    })
})

export const selectPortFilterOptions = createSelector(selectPorts, (ports) => {
  const options = {}
  ports.sort((a, b) => a.name.localeCompare(b.name))
  ports.forEach((port) => {
    options[port.port_code] = port.name
  })
  return options
})
