import React from 'react'
import { bool, func, node, oneOfType, arrayOf } from 'prop-types'
import './Drawer.scss'

import SvgIcon from '../SvgIcon/SvgIcon'

const Drawer = ({
  isOpen,
  closeHandler,
  children,
  isRightSide,
  hideDefaultCloseButton,
}) => (
  <div
    className={`drawer ${isRightSide ? 'right' : 'left'} ${
      isOpen ? 'open' : ''
    }`}
  >
    {children != null && <div className="panel">{children}</div>}

    {!hideDefaultCloseButton && (
      <button
        id="close-drawer"
        type="button"
        className="close-button"
        onClick={closeHandler}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="close-drawer" className="mobile">
          Close
        </label>
        <SvgIcon
          className="mobile"
          type="close-x"
          fill="blue"
          width="10"
          height="10"
          viewBox="0 0 16 16"
        />
        <SvgIcon className="desktop" type="close-x" fill="white" />
      </button>
    )}
  </div>
)

Drawer.propTypes = {
  isOpen: bool.isRequired,
  children: oneOfType([arrayOf(node), node]),
  closeHandler: func.isRequired,
  isRightSide: bool,
  hideDefaultCloseButton: bool,
}

Drawer.defaultProps = {
  children: null,
  isRightSide: false,
  hideDefaultCloseButton: false,
}

export default Drawer
