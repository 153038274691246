import { createSelector } from 'reselect'
import { selectSidebarViewResource } from '../view/viewSelectors'
import { selectSailings, selectPackages } from '../sailings/sailingsSelectors'
import { selectShipsIndexed } from '../ships/shipsSelectors'
import { selectPortsIndexed } from '../ports/portsSelectors'
import { selectRegionDeploymentsFilter } from '../filters/filtersSelectors'

const selectRegionViewDeployments = createSelector(
  selectSidebarViewResource,
  selectSailings,
  selectPackages,
  selectPortsIndexed,
  selectShipsIndexed,
  selectRegionDeploymentsFilter,
  (destinationCode, sailings, packages, ports, ships, filter) => {
    const resultPackages = {}

    const validateYearMonth = (yearMonth) => {
      const compareString = `${filter.year}${
        filter.month ? '-' + filter.month : ''
      }`
      return yearMonth.startsWith(compareString)
    }
    const validatePortOfCall = (packageData) => {
      if (filter.portOfCall?.length) {
        return !!filter.portOfCall.find((port) =>
          packageData.ports.includes(port)
        )
      }
      return true
    }

    // Get valid packages for region
    Object.entries(packages || {}).forEach(([packageId, packageData]) => {
      if (
        packageData.destinationCode === destinationCode &&
        validatePortOfCall(packageData)
      ) {
        resultPackages[packageId] = {
          name: packageData?.itinerary?.description || packageData.description,
          shortCode: packageId,
          sailDates: [],
          ship: ships[packageData.shipCode].name,
          shipCode: packageData.shipCode, // NOTE(ebarrett): Might be necessary for links
          ports: packageData.ports.map((portCode) => {
            if (!ports[portCode]) {
              return `MISSING ${portCode}`
            }
            return ports[portCode].name
          }),
          departurePort: ports?.[packageData?.departurePort]?.name || '',
        }
      }
    })

    // Now iterate through sailings to get package dates
    const yearMonths = Object.keys(sailings)
    yearMonths.forEach((yearMonth) => {
      if (!validateYearMonth(yearMonth)) {
        return
      }
      Object.values(sailings[yearMonth]).forEach((shipPackageList) => {
        shipPackageList.forEach(({ sailDate, packageId }) => {
          if (resultPackages[packageId] && validateYearMonth(sailDate)) {
            resultPackages[packageId].sailDates.push(sailDate)
          }
        })
      })
    })

    let tableRows = Object.values(resultPackages) // TODO(ebarrett): Sort by first sail date?

    // Filter by ship and date
    if (filter.ship?.length) {
      tableRows = tableRows.filter((row) => filter.ship.includes(row.shipCode))
    }
    tableRows = tableRows.filter((row) => !!row.sailDates.length)

    return tableRows
  }
)

export default selectRegionViewDeployments
