import { getBrands } from '../../api/api'

export const actionTypes = {
  FETCH_START: 'brands/FETCH_START',
  FETCH_SUCCESS: 'brands/FETCH_SUCCESS',
  FETCH_ERROR: 'brands/FETCH_ERROR',
}

export const fetchBrands = () => async (dispatch) => {
  dispatch({ type: actionTypes.FETCH_START })

  try {
    const { data } = await getBrands()
    return dispatch({ type: actionTypes.FETCH_SUCCESS, payload: data })
  } catch (error) {
    return dispatch({ type: actionTypes.FETCH_ERROR })
  }
}
