import React from 'react'
import { objectOf, string, func } from 'prop-types'
import './SelectWithLabel.scss'

const SelectWithLabel = ({
  label,
  values,
  placeholder,
  selectedValue,
  onChange,
}) => {
  const mapValuesToOptions = (localValues) =>
    Object.keys(localValues)
      .sort((a, b) => {
        const nameA = localValues[a].toUpperCase() // ignore upper and lowercase
        const nameB = localValues[b].toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        // names must be equal
        return 0
      })
      .map((value, index) => (
        <option key={index} value={value}>
          {localValues[value]}
        </option>
      ))

  return (
    <div className="select-with-label">
      <label htmlFor={`select-with-label-${label}`}>
        <div className="label-text">{label}</div>
        <select
          id={`select-with-label-${label}`}
          value={selectedValue}
          onChange={(e) => onChange(e.target.value)}
        >
          {placeholder && mapValuesToOptions({ '': placeholder })}
          {mapValuesToOptions(values)}
        </select>
      </label>
    </div>
  )
}

SelectWithLabel.propTypes = {
  label: string.isRequired,
  values: objectOf(Object).isRequired,
  placeholder: string,
  selectedValue: string,
  onChange: func,
}

SelectWithLabel.defaultProps = {
  placeholder: null,
  selectedValue: {},
  onChange: () => null,
}

export default SelectWithLabel
