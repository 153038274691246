import { createSelector } from 'reselect'
import { capitalize, kebabCase } from 'lodash'
import { ALLOWED_BRANDS } from '../../configuration/constants'

const getShipsState = (state) => state.ships

export const selectShips = createSelector(getShipsState, (state) => {
  // for issue: https://jira.verbinteractive.com/browse/RCL220-86
  const normalizedShips = (state.data || []).map((ship) => {
    const normalizedClass = capitalize(kebabCase(ship.class))
    return { ...ship, class: normalizedClass }
  })
  return normalizedShips.filter(
    (x) => ALLOWED_BRANDS.includes(x.brand_code) && !x.is_retired
  )
})

export const selectShipsIndexed = createSelector(selectShips, (ships) => {
  return ships.reduce((acc, cur) => {
    acc[cur.ship_code] = cur
    return acc
  }, {})
})

export const selectShipsListData = createSelector(
  selectShips,
  getShipsState,
  (ships, state) => {
    const { brandFilter, classFilter } = state

    let filteredShips = ships
    if (brandFilter) {
      filteredShips = filteredShips.filter(
        (ship) => ship.brand_code === brandFilter
      )
    }
    if (classFilter) {
      filteredShips = filteredShips.filter((ship) => ship.class === classFilter)
    }

    return filteredShips
      .sort((a, b) => (a.name < b.name ? -1 : 1))
      .map((ship) => {
        return {
          name: ship.name,
          shortCode: ship.ship_code,
        }
      })
  }
)

export const selectShipsBrandFilter = createSelector(getShipsState, (state) => {
  return state.brandFilter
})

export const selectShipsClassFilter = createSelector(getShipsState, (state) => {
  return state.classFilter
})

export const selectShipClassFilterOptions = createSelector(
  selectShips,
  (ships) => {
    const optionSet = new Set()

    ships.forEach((ship) => {
      if (typeof ship.class === 'string') {
        optionSet.add(ship.class)
      }
    })

    return [...optionSet]
      .sort((a, b) => a.localeCompare(b))
      .reduce((acc, cur) => {
        acc[cur] = cur
        return acc
      }, {})
  }
)

export const selectShipsFilterOptions = createSelector(selectShips, (ships) => {
  const options = {}
  ships.forEach((ship) => {
    options[ship.ship_code] = ship.name
  })
  return options
})
