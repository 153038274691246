import { createSelector } from 'reselect'
import { selectShips } from '../ships/shipsSelectors'
import { selectRegions } from '../regions/regionsSelectors'
import { selectPorts } from '../ports/portsSelectors'

const selectSearchableData = createSelector(
  selectShips,
  selectRegions,
  selectPorts,
  (ships, regions, ports) => {
    const compareName = (a, b) => (a.name < b.name ? -1 : 1)
    const shipOptions = ships
      .map((ship) => {
        return {
          type: 'ship',
          shortCode: ship.ship_code,
          name: ship.name,
        }
      })
      .sort(compareName)
    const regionOptions = regions
      .map((region) => {
        return {
          type: 'region',
          shortCode: region.destination_code,
          name: region.name,
        }
      })
      .sort(compareName)

    const portOptions = ports
      .map((port) => {
        const locations =
          port?.deployment_map_locations &&
          Array.isArray(port?.deployment_map_locations)
            ? port?.deployment_map_locations
            : []

        const countries =
          locations
            ?.map(({ location }) => location?.country)
            ?.filter((country) => !!country) || []

        const states =
          locations
            ?.map(({ location }) => location?.state)
            ?.filter((state) => !!state) || []

        const unique = new Set(countries)
        const uniqueStates = new Set(states)

        return {
          type: 'port',
          shortCode: port.port_code,
          name: port.name,
          countries: Array.from(unique),
          states: Array.from(uniqueStates),
        }
      })
      .sort(compareName)

    return [...shipOptions, ...regionOptions, ...portOptions]
  }
)

export default selectSearchableData
