import { createSelector } from 'reselect'
import { uniq } from 'lodash'
import { selectPortsIndexed } from '../ports/portsSelectors'
import { selectZoomLevel, selectZoomResource } from '../view/viewSelectors'
import { selectDepartureMonth } from '../filters/filtersSelectors'
import { selectSailings, selectPackages } from '../sailings/sailingsSelectors'
import { ZoomLevel, PinTypeName } from '../../configuration/constants'
import { selectShipsIndexed } from '../ships/shipsSelectors'
import { isValidPackageForDepartureMonth } from '../../utilities/dataUtils'
import { unescapeEncodedString } from '../../utilities/content/parseValidHtmlString'

function isValidPackageForZoom(packageInfo, zoomLevel, zoomResource) {
  switch (zoomLevel) {
    case ZoomLevel.WORLD:
    case ZoomLevel.PORT:
      return true
    case ZoomLevel.REGION:
      return packageInfo.destinationCode === zoomResource
    default:
      return false
  }
}

function mapPortToPinData(port, portExtra, sailings, allShips) {
  const packages = [...portExtra.packages] // array of ids
  const shipCodes = new Set()
  const sailDates = []
  Object.keys(sailings).forEach((year) => {
    Object.keys(sailings[year]).forEach((shipCode) => {
      const dates =
        sailings?.[year]?.[shipCode]?.map((sailing) => {
          if (packages.includes(sailing.packageId)) {
            return sailing.sailDate
          }
          return null
        }) || []
      sailDates.push(...dates.filter((date) => !!date))

      if (!shipCodes.has(shipCode)) {
        sailings[year][shipCode].forEach((shipSailing) => {
          if (packages.includes(shipSailing.packageId)) {
            shipCodes.add(shipCode)
          }
        })
      }
    })
  })

  const { location } = port.deployment_map_locations[0]
  const ships = [...shipCodes].map((shipCode) => {
    const ship = allShips[shipCode]
    return {
      name: ship?.name,
      brandCode: ship?.brand_code,
      shipCode: ship?.ship_code,
    }
  })
  const brands = uniq(ships?.map((ship) => ship.brandCode) || [])
  return {
    type: portExtra.type,
    label: unescapeEncodedString(port.name),
    shortCode: port.port_code,
    regions: [],
    lat: location.lat,
    lng: location.lng,
    ships,
    itineraryCount: packages.length,
    brands,
    dates: uniq(sailDates),
  }
}

const selectPortPinData = createSelector(
  selectPortsIndexed,
  selectSailings,
  selectPackages,
  selectZoomLevel,
  selectZoomResource,
  selectDepartureMonth,
  selectShipsIndexed,
  (
    allPorts,
    sailings,
    allPackages,
    zoomLevel,
    zoomResource,
    departureMonth,
    ships
  ) => {
    // Port extra data for current view
    // key: code_h, or code_c
    // value: { code, type, packages, }
    const portsInCurrentArea = {}

    // fill `portsInCurrentArea` from all the packages data
    const pickupPortsInCurrentView = ([packageId, packageInfo]) => {
      if (
        !isValidPackageForZoom(packageInfo, zoomLevel, zoomResource) ||
        !isValidPackageForDepartureMonth(
          packageId,
          packageInfo,
          sailings,
          departureMonth
        )
      ) {
        return
      }

      // first, add home port:
      const portCode = packageInfo.departurePort
      const homePortKey = portCode + '_h'
      portsInCurrentArea[homePortKey] = {
        code: portCode,
        type: PinTypeName.HOME_PORT,
        // temporarily set a container for next step to figure out!!
        packages: new Set(),
      }
      // then, add call of port:
      packageInfo.ports.forEach((shortCode) => {
        const callPortKey = shortCode + '_c'
        // prepare packages group in case of undefined
        const origPackages =
          portsInCurrentArea[callPortKey]?.packages || new Set()
        portsInCurrentArea[callPortKey] = {
          code: shortCode,
          type: PinTypeName.PORT_OF_CALL,
          packages: origPackages.add(packageId),
        }
      })
    }
    // 1. figure out which ports we care about at all
    // and differentiate between home port / port of call
    Object.entries(allPackages || {}).forEach(pickupPortsInCurrentView)

    // get packages for home ports in this view:
    const pickupPackagesForDeparturePort = ([packageId, packageInfo]) => {
      const portCode = packageInfo.departurePort
      const homePortKey = portCode + '_h'
      if (!portsInCurrentArea[homePortKey]) return // not found
      // add an itinerary
      portsInCurrentArea[homePortKey].packages.add(packageId)
    }
    // 2. add packages for home of port
    Object.entries(allPackages || {}).forEach(pickupPackagesForDeparturePort)

    // 3. Now output correct pin data
    const pins = []
    const isWorldView = zoomLevel === ZoomLevel.WORLD
    const allHomePorts = Object.values(portsInCurrentArea).filter(
      (port) => port.type === PinTypeName.HOME_PORT
    )
    const portsToDisplay = isWorldView
      ? allHomePorts
      : Object.values(portsInCurrentArea)
    // get all the home ports, default not showing up in the world view
    portsToDisplay.forEach((portExtra) => {
      const portDetails = allPorts[portExtra.code]
      // check this is a must initially!
      if (typeof portDetails === 'undefined') return
      // also need location info to put on map
      const hasLocation = portDetails.deployment_map_locations?.length
      if (!hasLocation) return
      // now its safe to put pin
      const pinData = mapPortToPinData(portDetails, portExtra, sailings, ships)
      pins.push(pinData)
    })

    return pins
  }
)

export default selectPortPinData
