import React from 'react'
import { shape, string, func } from 'prop-types'

import searchIcon from '../../assets/icons/search-icon.svg'
import shipIcon from '../../assets/icons/ship-icon.svg'
import regionIcon from '../../assets/icons/destinations-icon.svg'
import portIcon from '../../assets/icons/anchor-icon.svg'
import documentIcon from '../../assets/icons/document-icon.svg'

const MapSearchResult = ({ searchResult, onSelect }) => {
  let icon = searchIcon
  const { name, type, shortCode } = searchResult

  switch (type) {
    case 'region':
      icon = regionIcon
      break
    case 'port':
      icon = portIcon
      break
    case 'ship':
      icon = shipIcon
      break
    case 'document':
      icon = documentIcon
      break
    default:
      icon = searchIcon
      break
  }

  return (
    <button
      type="button"
      className="basic-link component-map-search-results__result"
      onClick={() => onSelect(type, shortCode)}
    >
      <img
        src={icon}
        alt=""
        className="component-map-search-results__result__icon"
      />
      <span className="component-map-search-results__result__name">{name}</span>
    </button>
  )
}

MapSearchResult.propTypes = {
  onSelect: func.isRequired, // params: (type, shortCode)
  searchResult: shape({
    name: string.isRequired,
    type: string.isRequired,
    shortCode: string.isRequired,
  }).isRequired,
}

export default MapSearchResult
