import triggerAnalyticsEvent from './triggerAnalyticsEvent'

const analyticsPageView = (dispatchEvent) => {
  const analyticsEvent = 'shipLocatorPageView'
  const data = dispatchEvent?.payload
  const { agent, agency } = data
  triggerAnalyticsEvent(analyticsEvent, {
    agentId: agent?.rccl_id,
    agentName: agent?.name,
    agentEmail: agent?.email,
    agencyName: agency?.name,
  })
}

export default analyticsPageView
