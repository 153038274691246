import React from 'react'
import './ArticleContainer.scss'
import ClickthroughHeader from '../../components/ClickthroughHeader/ClickthroughHeader'
import announcementsIcon from '../../assets/icons/menu-announcements-icon.svg'
import bahamasHouses from '../../assets/images/bahamas-houses.png'
import ClickthroughDetails from '../../components/ClickthroughDetails/ClickthroughDetails'
import DeploymentChangesTable from '../../components/DeploymentChangesTable/DeploymentChangesTable'
import ExpandableCardLayout from '../../components/ExpandableCardLayout/ExpandableCardLayout'
import AnnouncementCard from '../../components/AnnouncementCard/AnnouncementCard'

const ArticleContainer = () => {
  const MOCK_ANNOUNCEMENT = {
    isNew: false,
    date: 'October 4, 2022',
    url: '',
    title: 'Symphony of the Seas is now operating in the Bahamas through 2022',
  }
  const MOCK_ANNOUNCEMENTS = [...new Array(4).fill(MOCK_ANNOUNCEMENT)]

  const MOCK_DETAILS = {
    bio: 'KAs Royal Caribbean® continues to prepare for our return to service, we’ve listened to our guests and valued travel partners, and are ready to share some Winter 2021 – 2022 deployment updates. We’ve modified our schedule to make room for some incredible new itineraries. Your clients can now enjoy short Bahama escapes onboard Symphony of the Seas® from Miami. Or delve down under on extended Australia adventures onboard Quantum® or Serenade of the Seas®.    As Royal Caribbean® continues to prepare for our return to service, we’ve listened to our guests and valued travel partners, and are ready to share some Winter 2021 – 2022 deployment updates. We’ve modified our schedule to make room for some incredible new itineraries. Your clients can now enjoy short Bahama escapes onboard Symphony of the Seas® from Miami. Or delve down under on extended Australia adventures onboard Quantum® or Serenade of the Seas®.',
  }

  const MOCK_DEPLOYMENT_A = {
    originalShip: 'Quantum of the Seas',
    newShip: '—',
    itinerary: '8/9/10 -Night Australia',
    embarkationPort: 'Sydney, Australia',
    itineraryStatus: 'Cancelled',
    timing: '',
  }

  const MOCK_DEPLOYMENT_B = {
    originalShip: 'Quantum of the Seas',
    newShip: 'Symphony of the Seas',
    itinerary: '8/9/10 -Night Australia',
    embarkationPort: 'Sydney, Australia',
    itineraryStatus: 'Cancelled',
    timing: '10/21',
  }

  const MOCK_DEPLOYMENT_ROWS = [
    MOCK_DEPLOYMENT_A,
    MOCK_DEPLOYMENT_B,
    MOCK_DEPLOYMENT_A,
    MOCK_DEPLOYMENT_B,
    MOCK_DEPLOYMENT_A,
    MOCK_DEPLOYMENT_B,
    MOCK_DEPLOYMENT_A,
    MOCK_DEPLOYMENT_B,
    MOCK_DEPLOYMENT_A,
    MOCK_DEPLOYMENT_B,
    MOCK_DEPLOYMENT_A,
    MOCK_DEPLOYMENT_B,
  ]

  function generateDetailsComponentParam() {
    return {
      imageSrc: bahamasHouses,
      sections: [
        {
          type: 'bio',
          label: 'About',
          value: MOCK_DETAILS.bio,
        },
      ],
    }
  }

  const clickthroughHeader = (
    <ClickthroughHeader title="Symphony of the Seas is now operating in the Bahamas through 2022" />
  )

  return (
    <div className="article-container">
      <header className="mobile">
        <div className="title-row">
          <img src={announcementsIcon} alt="" />
          <div className="title">Announcements</div>
        </div>
      </header>
      <header className="desktop">{clickthroughHeader}</header>
      <main>
        <div className="mobile">{clickthroughHeader}</div>

        <ClickthroughDetails {...generateDetailsComponentParam()} />

        <div className="title">Deployment Changes</div>

        <DeploymentChangesTable deploymentChanges={MOCK_DEPLOYMENT_ROWS} />

        <div className="title">Latest News</div>

        <ExpandableCardLayout showRows={1}>
          {MOCK_ANNOUNCEMENTS.map((item, index) => (
            <AnnouncementCard announcement={item} key={index} />
          ))}
        </ExpandableCardLayout>
      </main>
    </div>
  )
}

export default ArticleContainer
