import React from 'react'
import { func, arrayOf, shape, string } from 'prop-types'
import './PinFilters.scss'

import PinItem from './PinItem'

const PinFilters = ({ pinTypes, selectedPins, updateFilters }) => (
  <div className="component-pin-filters-wrapper">
    <div className="component-pin-filters">
      <span className="component-pin-filters__title">Show:</span>
      <ul className="component-pin-filters__pins">
        {pinTypes.map((pin, idx) => (
          <PinItem
            pin={pin}
            updateFilters={updateFilters}
            isActive={selectedPins.includes(pin.type)}
            key={idx}
          />
        ))}
      </ul>
    </div>
  </div>
)

PinFilters.propTypes = {
  updateFilters: func.isRequired,
  pinTypes: arrayOf(
    shape({
      type: string.isRequired,
      label: string.isRequired,
      icon: string.isRequired,
    })
  ),
  selectedPins: arrayOf(string),
}

PinFilters.defaultProps = {
  pinTypes: [],
  selectedPins: [],
}

export default PinFilters
