import { createSelector } from 'reselect'

const getRegionState = (state) => state.regions

export const selectRegions = createSelector(
  getRegionState,
  (state) => state.data || []
)

export const selectRegionsIndexed = createSelector(selectRegions, (regions) => {
  return regions.reduce((acc, cur) => {
    acc[cur.destination_code] = cur
    return acc
  }, {})
})

export const selectRegionListData = createSelector(selectRegions, (regions) => {
  return regions
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .map((region) => {
      return {
        name: region.name,
        shortCode: region.destination_code,
      }
    })
})

export const selectRegionPinData = createSelector(selectRegions, (regions) => {
  return regions
    .filter((region) => !!region.deployment_map_location)
    .map((region) => {
      return {
        type: 'region',
        label: region.name,
        shortCode: region.destination_code,
        lat: region.deployment_map_location.lat,
        lng: region.deployment_map_location.lng,
      }
    })
})

export const selectRegionFilterOptions = createSelector(
  selectRegions,
  (regions) => {
    const options = {}
    regions.sort((a, b) => a.name.localeCompare(b.name))
    regions.forEach((region) => {
      options[region.destination_code] = region.name
    })
    return options
  }
)
