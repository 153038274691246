import React, { useState, useRef } from 'react'
import { arrayOf, shape, func } from 'prop-types'

import FilterSection from './FilterSection'
import UseOutsideClick from '../UseOutsideClick/UseOutsideClick'

import './Filters.scss'

/**
 * Popup modal for DeploymentTable use.
 * renaming might required cause its not accurate
 */

const Filters = ({
  applyFilters,
  closePopout,
  filterSections,
  filterValues,
}) => {
  const ref = useRef()
  UseOutsideClick(ref, closePopout)

  const [localSelection, setLocalSelection] = useState({ ...filterValues })

  const updateLocalSelection = (key, values) => {
    setLocalSelection({
      ...localSelection,
      [key]: values,
    })
  }
  const clearFilters = () => {
    const cleared = {}
    filterSections.forEach((section) => {
      cleared[section.key] = []
    })
    setLocalSelection(cleared)
  }

  return (
    <div className="flex-center filters-popout">
      <div className="filters-popout__container" ref={ref}>
        <div className="filters-popout__title-bar">
          <span className="filters-popout__title-bar__title">
            Refine Criteria
          </span>
          <button
            className="filters-popout__title-bar__close"
            onClick={closePopout}
          >
            close X
          </button>
        </div>
        <div className="filters-popout__content">
          {filterSections.map((section) => {
            return (
              <FilterSection
                key={section.key}
                title={section.title}
                options={section.options}
                type={section.type}
                selectedOptions={localSelection[section.key]}
                updateSelections={(options) => {
                  updateLocalSelection(section.key, options)
                }}
              />
            )
          })}
        </div>
        <div className="filters-popout__actions">
          <button
            className="filters-popout__actions__clear"
            onClick={clearFilters}
          >
            Clear All <span className="close-x">X</span>
          </button>
          <button
            className="filters-popout__actions__apply"
            onClick={() => applyFilters(localSelection)}
          >
            Apply Filters <span className="apply-arrow">❯</span>
          </button>
        </div>
      </div>
    </div>
  )
}

Filters.propTypes = {
  applyFilters: func.isRequired,
  closePopout: func.isRequired,
  filterSections: arrayOf(shape()).isRequired,
  filterValues: shape().isRequired,
}

export default Filters
