import { createSelector } from 'reselect'
import { selectSidebarViewResource } from '../view/viewSelectors'
import { selectPortsIndexed } from '../ports/portsSelectors'
import { selectPackages } from '../sailings/sailingsSelectors'
import { selectShipsIndexed } from '../ships/shipsSelectors'
import parseValidHtmlString from '../../utilities/content/parseValidHtmlString'

// these may come from a feed at some point
// const PORT_ALERT_TITLE = 'Healthy Cruising Protocols'
// const PORT_ALERT_MESSAGE = `<p>As we return to sea, your clients' health and safety remains our top priority.
// We've partnered with expert medical and scientific minds to guide us in the development of comprehensive protocols
// to protect your clients and the ones they love. And we're working with local health authorities in our homeport
// countries to ensure our guests meet current inbound travel requirements. We're continually evaluating these protocols
// and will make updates as public health standards evolve.</p>
// <p>Please <a href="https://www.healthycruising.com/" target="_blank">click here</a> to visit our Healthy Cruising hub
// for the latest information on vaccination requirements as well as other health and safety protocols.</p>`

const selectPortViewContent = createSelector(
  selectPortsIndexed,
  selectSidebarViewResource,
  selectPackages,
  selectShipsIndexed,
  (ports, portCode, packages, ships) => {
    const port = ports[portCode]

    if (!port) {
      return null // should be unreachable
    }

    // Determine visiting ships
    const visitingShipCodes = new Set()
    Object.values(packages).forEach((packageInfo) => {
      if (packageInfo.ports.includes(portCode)) {
        visitingShipCodes.add(packageInfo.shipCode)
      }
    })
    const visitingShips = [...visitingShipCodes]
      .map((shipCode) => ships[shipCode].name)
      .join(', ')

    let bio
    if (port.body) {
      bio = port.body
    } else if (port.body_r) {
      bio = port.body_r
    } else {
      bio = port.body_c
    }

    return {
      name: port.name,
      imageSrc: port?.image?.[0]?.file || '',
      visitingShips,
      bio: parseValidHtmlString(String(bio)),
      // alertMessage: {
      //   title: PORT_ALERT_TITLE,
      //   body: PORT_ALERT_MESSAGE,
      // },
    }
  }
)

export default selectPortViewContent
