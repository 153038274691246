import { createSelector } from 'reselect'

const getViewState = (state) => state.view

export const selectMobileMenuIsOpen = createSelector(getViewState, (state) => {
  return state.mobileMenuIsOpen
})

export const selectSidebarView = createSelector(getViewState, (state) => {
  return state.sidebarView
})

export const selectSidebarViewResource = createSelector(
  getViewState,
  (state) => {
    return state.sidebarViewResource
  }
)

export const selectSidebarPinType = createSelector(getViewState, (state) => {
  return state.pinType
})

export const selectZoomDetails = createSelector(getViewState, (state) => {
  return {
    zoomLevel: state.zoomLevel,
    zoomResource: state.zoomResource,
    upperZoomLevel: state.upperZoomLevel,
    upperZoomResource: state.upperZoomResource,
  }
})

export const selectZoomLevel = createSelector(
  getViewState,
  (state) => state.zoomLevel
)
export const selectZoomResource = createSelector(
  getViewState,
  (state) => state.zoomResource
)

export const selectFilterSidebarViewIsOpen = createSelector(
  getViewState,
  (state) => state.filterDrawerIsOpen
)

export const selectViewOrigin = createSelector(
  getViewState,
  (state) => state.viewOrigin
)
