import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { selectBrands } from '../../redux/brands/brandsSelectors'

import './PinFiltersExtended.scss'

import resultIcon from '../../assets/icons/result.svg'
import calendarIcon from '../../assets/icons/calendar.svg'
import shipIcon from '../../assets/icons/ship.svg'

const year = moment().startOf('year')

const PinFiltersExtended = ({
  toggleActiveBrands,
  activeBrands,
  toggleRange,
  activeDateRange,
  toggleYear,
  activeYear,
  activeMonth,
  toggleMonth,
}) => {
  const brandsData = useSelector(selectBrands)

  return (
    <div className="pin-filters-extended">
      <div className="result-select">
        <img src={resultIcon} alt="result-icon" />
        <p>Results From</p>
      </div>
      <hr />
      <p>Show deployments for the next:</p>
      <div className="pin-filters-date-ranges">
        {Array.from({ length: 3 }, (_, i) => {
          const value = (i + 1) * 30
          const isActive = activeDateRange === value
          return (
            <button
              key={i}
              onClick={() => toggleRange(value)}
              className={`pin-filters-date-range ${isActive ? 'active' : ''}`}
            >
              {value} days
            </button>
          )
        })}
      </div>
      <div className="result-select">
        <img src={calendarIcon} alt="calendar-icon" />
        <p>Select a Year / Month</p>
      </div>
      <hr />
      <div className="pin-filters-years">
        {Array.from({ length: 3 }, (_, i) => {
          const value = year.clone().add(i, 'years').format('YYYY')
          const isActive = activeYear === value
          return (
            <button
              key={i}
              onClick={() => toggleYear(value)}
              className={`pin-filters-date-year ${isActive ? 'active' : ''}`}
            >
              {value}
            </button>
          )
        })}
      </div>
      <div className="pin-filters-months">
        {Array.from({ length: 12 }, (_, i) => {
          const value = moment().month(i).format('MMM')
          const isActive = activeMonth === value

          return (
            <button
              key={i}
              onClick={() => toggleMonth(value)}
              className={`pin-filters-month ${isActive ? 'active' : ''}`}
            >
              {value}
            </button>
          )
        })}
      </div>
      <div className="result-select">
        <img src={shipIcon} alt="ship-icon" />
        <p>See Results From</p>
      </div>
      <hr />
      <div className="pin-filters-brands">
        {brandsData?.length > 0
          ? brandsData.map((brand) => {
              const isActive = activeBrands?.some(
                (brandCode) => brandCode === brand.brand_code
              )

              return (
                <button
                  className={`pin-filters-brand ${isActive ? 'active' : ''}`}
                  key={brand.name}
                  onClick={() => toggleActiveBrands(brand.brand_code)}
                >
                  {brand.name}
                </button>
              )
            })
          : null}
      </div>
    </div>
  )
}

PinFiltersExtended.defaultProps = {
  activeDateRange: null,
  activeYear: null,
  activeMonth: null,
}

PinFiltersExtended.propTypes = {
  toggleActiveBrands: PropTypes.func.isRequired,
  activeBrands: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleRange: PropTypes.func.isRequired,
  activeDateRange: PropTypes.number,
  toggleYear: PropTypes.func.isRequired,
  activeYear: PropTypes.string,
  activeMonth: PropTypes.string,
  toggleMonth: PropTypes.func.isRequired,
}

export default PinFiltersExtended
