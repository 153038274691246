import triggerAnalyticsEvent from './triggerAnalyticsEvent'

const analyticsItineraryModalOpened = ({
  brand,
  ship,
  sailsTo,
  modalOriginationPoint,
  region,
}) => {
  triggerAnalyticsEvent('ShipItineraryDetailsModal', {
    brand,
    sailsTo,
    ship,
    modalOriginationPoint,
    region,
  })
}

export default analyticsItineraryModalOpened
