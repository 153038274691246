import { ZoomLevel } from '../configuration/constants'

export const MAPBOX_ZOOM_STEP = 0.5

export function getBoundingBoxFromPins(pins, lngLatPadding = 0) {
  if (!pins?.length) {
    return [
      [-180, -90],
      [180, 90],
    ]
  }

  let lngs = pins.map((pin) => pin.lng)
  const lats = pins.map((pin) => pin.lat)
  const isSpanningAntimeridian = lngs.find((x) => x > -100 && x < 100) == null

  if (isSpanningAntimeridian) {
    lngs = lngs.map((x) => (x < 0 ? x + 360 : x))
  }

  const latMin = Math.min(...lats) - lngLatPadding
  const latMax = Math.max(...lats) + lngLatPadding
  const lngMin = Math.min(...lngs) - lngLatPadding
  const lngMax = Math.max(...lngs) + lngLatPadding

  return [
    [lngMin, latMin],
    [lngMax, latMax],
  ]
}

export const fitBoundsOptionsMobile = {
  [ZoomLevel.WORLD]: {
    padding: { left: 0, right: 0, top: 0, bottom: 0 },
    duration: 1500,
    linear: true,
  },
  [ZoomLevel.REGION]: {
    padding: { left: 20, right: 100, top: 50, bottom: 50 },
    duration: 1500,
    linear: true,
  },
  [ZoomLevel.PORT]: {
    padding: { left: 10, right: 100, top: 60, bottom: 100 },
    duration: 2300,
  },
}

export const fitBoundsOptionsDesktop = {
  [ZoomLevel.WORLD]: {
    padding: { left: 50, right: 200, top: 120, bottom: 100 },
    duration: 1500,
    linear: true,
  },
  [ZoomLevel.REGION]: {
    padding: { left: 200, right: 200, top: 200, bottom: 200 },
    duration: 1500,
    linear: true,
  },
  [ZoomLevel.PORT]: {
    padding: { left: 50, right: 200, top: 120, bottom: 200 },
    duration: 2300,
  },
}
