export const actionTypes = {
  SET_DEPARTURE_MONTH: 'filters/SET_DEPARTURE_MONTH',
  SET_DEPLOYMENT_FILTER: 'filters/SET_DEPLOYMENT_FILTER',
  TOGGLE_PIN_FILTER: 'filters/TOGGLE_PIN_FILTER',
  SET_SHIP_DEPLOYMENTS_FILTER: 'filters/SET_SHIP_DEPLOYMENTS_FILTER',
  SET_REGION_DEPLOYMENTS_FILTER: 'filters/SET_REGION_DEPLOYMENTS_FILTER',
  SET_PORT_DEPLOYMENTS_FILTER: 'filters/SET_PORT_DEPLOYMENTS_FILTER',
}

export const setMonthFilter = (params) => ({
  type: actionTypes.SET_DEPARTURE_MONTH,
  payload: params,
})

export const setAllDeploymentsFilter = (key, value) => ({
  type: actionTypes.SET_DEPLOYMENT_FILTER,
  payload: { [key]: value },
})

export const togglePinFilter = (zoomLevel, pinTypeName) => ({
  type: actionTypes.TOGGLE_PIN_FILTER,
  payload: {
    zoomLevel,
    pinTypeName,
  },
})

export const setShipDeploymentsFilter = (updates) => ({
  type: actionTypes.SET_SHIP_DEPLOYMENTS_FILTER,
  payload: updates,
})

export const setRegionDeploymentsFilter = (updates) => ({
  type: actionTypes.SET_REGION_DEPLOYMENTS_FILTER,
  payload: updates,
})

export const setPortDeploymentsFilter = (updates) => ({
  type: actionTypes.SET_PORT_DEPLOYMENTS_FILTER,
  payload: updates,
})
