import React from 'react'
import { bool, shape, string } from 'prop-types'
import './AnnouncementCard.scss'

import chevronRight from '../../assets/icons/chevron-right.svg'

const AnnouncementCard = ({ announcement }) => (
  <div className="announcement-card card">
    <div className="new-tag-container">
      {announcement.isNew ? <div className="new-tag">New</div> : null}
    </div>
    {announcement.date ? <div className="date">{announcement.date}</div> : null}
    <div className="columns">
      <div className="announcement-title">{announcement.title}</div>
      <div className="link">
        {announcement.url && (
          <>
            <a href={announcement.url} target="_blank" rel="noreferrer">
              View More Information
            </a>
            <img src={chevronRight} alt="" />
          </>
        )}
      </div>
    </div>
    {announcement.shortDescription ? (
      <div
        className="description"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: announcement.shortDescription }}
      />
    ) : null}
  </div>
)

AnnouncementCard.propTypes = {
  announcement: shape({
    isNew: bool.isRequired,
    date: string.isRequired,
    title: string.isRequired,
    shortDescription: string.isRequired,
    url: string.isRequired,
  }).isRequired,
}

export default AnnouncementCard
