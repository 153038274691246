import React from 'react'
import { func, string } from 'prop-types'

import './BackToUpperView.scss'
import PinYellowSmall from '../../assets/icons/pin-yellow-sm.svg'
import PinPurpleAnchor from '../../assets/icons/purple-anchor-icon.svg'

import BackIcon from '../../assets/icons/back-arrow.svg'
import { ZoomLevel } from '../../configuration/constants'

const BackToUpperView = ({
  zoomLevel, // WORLD, REGION, PORT
  viewName,
  backButtonLabel, // BACK TO WORLD or BACK TO REGION VIEW
  backToUpperViewHandler,
}) => {
  let imgSrc
  let viewLabel
  let hidden = false

  switch (zoomLevel) {
    case ZoomLevel.PORT:
      imgSrc = PinPurpleAnchor
      viewLabel = 'HOME PORT:'
      break
    case ZoomLevel.REGION:
      imgSrc = PinYellowSmall
      viewLabel = 'REGION:'
      break
    case ZoomLevel.WORLD:
    default:
      // NOTE(ebarrett): Revisit if we can go from port to world
      imgSrc = PinYellowSmall
      viewLabel = 'REGION:'
      hidden = true
      break
  }

  return (
    <div className={`back-to-upper-view ${hidden ? 'hidden' : ''}`}>
      <button
        type="button"
        className="mobile btn btn-text-only back-button-on-right"
        onClick={backToUpperViewHandler}
      >
        <img src={BackIcon} alt="backtoworld" className="back-button-icon" />
        <span className="back-button-label">{backButtonLabel}</span>
      </button>
      <p className="region-label">
        <img src={imgSrc} alt="pin" />
        <span>{viewLabel}</span>
      </p>
      <h1 className="region-name">{viewName}</h1>
      <button
        type="button"
        className="desktop btn btn-text-only back-button-on-right"
        onClick={backToUpperViewHandler}
      >
        <img src={BackIcon} alt="backtoworld" className="back-button-icon" />
        <span className="back-button-label">
          {backButtonLabel}
          <span className="desktop"> View</span>
        </span>
      </button>
    </div>
  )
}

BackToUpperView.propTypes = {
  zoomLevel: string.isRequired,
  viewName: string.isRequired,
  backButtonLabel: string.isRequired,
  backToUpperViewHandler: func.isRequired,
}

export default BackToUpperView
