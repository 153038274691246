import React from 'react'
import { bool, func } from 'prop-types'

import './MapZoomControl.scss'

import ZoomInIcon from '../../assets/icons/zoom-in-icon.svg'
import ZoomOutIcon from '../../assets/icons/zoom-out-icon.svg'

const MapZoomControl = ({ zoomInHandler, zoomOutHandler, disabled }) => {
  return (
    <div className="map-zoom-control">
      <button
        type="button"
        className="btn btn-normal zoom-in"
        disabled={disabled}
        onClick={zoomInHandler}
      >
        <img src={ZoomInIcon} alt="zoom in" className="icon" />
      </button>
      <button
        type="button"
        className="btn btn-normal zoom-out"
        disabled={disabled}
        onClick={zoomOutHandler}
      >
        <img src={ZoomOutIcon} alt="zoom out" className="icon" />
      </button>
    </div>
  )
}

MapZoomControl.propTypes = {
  disabled: bool.isRequired,
  zoomInHandler: func.isRequired,
  zoomOutHandler: func.isRequired,
}

export default MapZoomControl
