import React from 'react'
import { string } from 'prop-types'

import { ReactComponent as CloseXIcon } from '../../assets/icons/close-x-dynamic.svg'

const SvgIcon = ({ type, fill, width, height, viewBox, className, style }) => {
  let result = null
  let svgProps = { fill, className }

  if (width && height && viewBox) {
    svgProps = {
      ...svgProps,
      width,
      height,
      viewBox,
      style,
    }
  }

  switch (type) {
    case 'close-x': {
      result = <CloseXIcon {...svgProps} />
      break
    }
    default: {
      result = null
      break
    }
  }

  return result
}

SvgIcon.propTypes = {
  type: string.isRequired,
  width: string,
  height: string,
  viewBox: string,
  className: string,
  fill: string,
}

SvgIcon.defaultProps = {
  fill: 'white',
  className: '',
}

export default SvgIcon
