import { createSelector } from 'reselect'

import selectPortPinData from './selectPortPinData'
import { selectZoomDetails } from '../view/viewSelectors'
import {
  REGION_ZOOM_PORT_CODES,
  ZoomLevel,
} from '../../configuration/constants'

const selectRegionZoomInitialBoundingBox = createSelector(
  selectPortPinData,
  selectZoomDetails,
  (portPins, zoomDetails) => {
    const { zoomLevel, zoomResource } = zoomDetails

    if (zoomLevel === ZoomLevel.WORLD) return []

    const portCodesInRegion = REGION_ZOOM_PORT_CODES[zoomResource]
    const pickedPins = portPins.filter((pin) => {
      if (!portCodesInRegion || !portCodesInRegion.length) return false
      if (portCodesInRegion[0] === '*') return true
      return portCodesInRegion.includes(pin.shortCode)
    })

    return pickedPins
  }
)

export default selectRegionZoomInitialBoundingBox
