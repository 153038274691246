import { createSelector } from 'reselect'
import {
  ALLOWED_BRANDS,
  ZoomLevel,
  MONTHS,
} from '../../configuration/constants'
import { selectZoomDetails } from '../view/viewSelectors'
import { selectShipsIndexed } from '../ships/shipsSelectors'

const getSailingsState = (state) => state.sailings

export const selectPackages = createSelector(
  getSailingsState,
  selectShipsIndexed,
  (state, ships) => {
    const packageIds = Object.keys(state.data.packages || {})
    const result = {}
    packageIds.forEach((id) => {
      if (
        ALLOWED_BRANDS.includes(state.data.packages[id].brand) &&
        ships[state.data.packages[id].shipCode]
      ) {
        result[id] = state.data.packages[id]
      }
    })
    return result
  }
)

export const selectSailings = createSelector(
  getSailingsState,
  selectPackages,
  (state, packages) => {
    const result = {}
    Object.entries(state.data.sailings || {}).forEach(([yearMonth, ships]) => {
      result[yearMonth] = {}
      Object.keys(ships).forEach((shipKey) => {
        const validSailings = ships[shipKey].filter(
          (x) => !!packages[x.packageId]
        )
        if (validSailings.length) {
          result[yearMonth][shipKey] = validSailings
        }
      })
    })
    return result
  }
)

export const selectFilterableMonths = createSelector(
  selectSailings,
  (sailings) => Object.keys(sailings)
)

export const selectFilterableYears = createSelector(
  selectFilterableMonths,
  (months) => {
    const years = []
    months.forEach((month) => {
      const [year] = month.split('-')
      if (!years.includes(year)) {
        years.push(year)
      }
    })
    return years
  }
)

/**
 * zoom-level perceivable months selector for packages
 */
export const selectAvailableMonthsByYear = createSelector(
  selectFilterableYears,
  selectFilterableMonths, // months array, ['YYYY-MM', ...]
  selectZoomDetails,
  selectSailings,
  selectPackages,
  (YEARS, availableMonths, mapState, allSailings, allPackages) => {
    // Reusable function generator for world|region|port,
    // different view using different checker function
    const composeMonthsByYear = (monthChecker) => (year) => ({
      year,
      months: MONTHS.map((label, index) => {
        const monthLength = (index + 1).toString().length
        const twoDigitsMonth =
          monthLength > 1 ? `${index + 1}` : `0${index + 1}`
        const yearAndMonth = `${year}-${twoDigitsMonth}` // this is the key of each sailing key
        const includedInMonths = monthChecker(yearAndMonth)
        return {
          month: yearAndMonth,
          label,
          disabled: !includedInMonths,
        }
      }),
    })

    // 1. First, get all the months group by year all over the world, initially!
    if (mapState.zoomLevel === ZoomLevel.WORLD) {
      const monthsForWorldwide = composeMonthsByYear((yearAndMonth) =>
        availableMonths.includes(yearAndMonth)
      )
      return YEARS.map(monthsForWorldwide)
    }

    // this filter also reusable both for port and region
    const filterMonthsByField = (fieldName) => {
      const monthCache = {}
      Object.entries(allSailings || {}).forEach(([month, packagesForShip]) => {
        Object.values(packagesForShip).forEach((packageList) => {
          packageList.forEach(({ packageId }) => {
            if (!allPackages[packageId]) return false // safety check
            // get current package region or port
            const packagePortOrDestination = allPackages[packageId][fieldName]
            // found one match with current view type
            if (packagePortOrDestination === mapState.zoomResource) {
              // save it temporarily
              monthCache[month] = true
            }
            return true // have to return something for lint reason
          })
        })
      })
      return monthCache
    }

    // 2. Now, assume we are in region view...
    // Then, trying to collect those months which have packages in current region
    // Only return months where there are sailings for packages in that region
    if (mapState.zoomLevel === ZoomLevel.REGION) {
      const monthsInRegionCache = filterMonthsByField('destinationCode')
      const monthsForRegion = composeMonthsByYear(
        (yearAndMonth) => !!monthsInRegionCache[yearAndMonth]
      )
      return YEARS.map(monthsForRegion)
    }

    // 3. Now, assume we are in port view...
    // Last, trying to collect those months which have packages in current port
    // Only return months where there are sailings for packages departing from that port
    if (mapState.zoomLevel === ZoomLevel.PORT) {
      const monthsInPortCache = filterMonthsByField('departurePort')
      const monthsForPort = composeMonthsByYear(
        (yearAndMonth) => !!monthsInPortCache[yearAndMonth]
      )
      return YEARS.map(monthsForPort)
    }

    return [] // must return something in arrow function
  }
)
